<div class="store-carousel">
    <div class="theme-container text-center title-main">Tiendas Participantes</div>
    <div *ngIf="stores.length == 0" class="w-100 py-4 text-center">
        <span>Sin registros</span>
    </div>
    <ng-container *ngIf="stores.length > 0">
        <div class="swiper-container h-100" [swiper]="config">
            <div class="swiper-wrapper h-100">      
                <div *ngFor="let store of stores" class="swiper-slide">
                    <div class="p-0">
                        <a [routerLink]="['/store', store.nombre, store.id]" class="store-item">
                            <img [attr.data-src]="plazzaService.getPathImageStore(store.id)" class="swiper-lazy" 
                            onerror="this.src='assets/images/others/not-found.png';">
                        </a>
                        <div class="swiper-lazy-preloader"></div>
                    </div>
                </div>
            </div> 
            <div class="text-right"><a mat-button routerLink="/store" class="link"><i>Ver todos</i></a></div> 
        </div>
        <button mat-icon-button class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
        <button mat-icon-button class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button>
    </ng-container>
    <hr>
</div>