<div class="content-block">
  <!--<mat-card class="box-content">-->
    <!--<h2 style="text-align: center;">Categorías</h2>
    <div fxLayout="row wrap" fxLayoutAlign="start none">
      <mat-card fxLayout="column" fxLayoutAlign="start center" class="categories" *ngFor="let category of result" fxFlex="10">
        <img src="assets/images/funciona/como_funciona.webp" class="category-img"/><br>
        <p>{{category}}</p>
      </mat-card>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start none">
      <mat-card fxLayout="column" fxLayoutAlign="start center" fxFlex="20" class="gurus" *ngFor="let gurus of gurus" >
        <img src="assets/images/funciona/calidad.webp" class="guru-portada">
        <img src="assets/images/funciona/como_funciona.webp" class="guru-perfil"/>
        <img src="assets/images/flag/flag_honduras.png" class="flag"/>
        <p class="estado-disponible" *ngIf="gurus?.state">Disponible</p>
        <p class="estado-no-disponible" *ngIf="!gurus?.state">No disponible</p>
        <p class="guru-nombre">{{gurus?.name}}</p>
        <p>{{gurus?.score}}</p>
        
        <button class="btn-ver" role="button">VER MÁS</button>
      </mat-card>
    </div> 
    <h2>Servicios</h2>
    <div fxLayout="row wrap" fxLayoutAlign="start none">
      <mat-card fxLayout="column" fxLayoutAlign="start center" fxFlex="20" class="services" *ngFor="let service of services">
        <img src="assets/images/funciona/calidad.webp" class="service-img">
        <p class="service-title">{{service?.title}}</p>
        <p class="service-subtitle">{{service?.sub_title}}</p>
        <p class="service-price">Precio: LPS. {{service?.price}}</p>
        <p class="service-description">{{service?.description}}</p>
        <button class="service-btn"><mat-icon style="position: relative; top: 5px;">shopping_cart</mat-icon> Cotizar</button>
      </mat-card>
    </div>
  -->

    <div fxLayout="row wrap" class="products-wrapper">
      <div fxFlex="100" class="col">
          <mat-card class="product-item">
            <h2>Gurús</h2>
            <div *ngFor="let guru of gurus;let i = index">
              <div fxLayout="row wrap">
                <div fxFlex="80" fxFlex.lt-sm="100">
                  <div fxLayoutAlign=" center">
                    <img src="assets/images/funciona/como_funciona.webp" class="guru-perfil"/>
                  </div>
                  <div fxFlex="100" fxFlex.gt-xs="80" class="p-2" fxLayout="column" fxLayoutAlign="start">
                    <div fxLayoutAlign="start">
                      <p class="guru-nombre">{{guru?.name}} </p>  
                      <p class="estado-disponible" *ngIf="guru?.state">Disponible</p>
                      <p class="estado-no-disponible" *ngIf="!guru?.state">No disponible</p>
                    </div>
                    <div class="px-1">
                      <app-rating [ratingsCount]="5" [ratingsValue]="guru?.score" [direction]="'row'"></app-rating>
                    </div>
                    <img src="assets/images/flag/flag_honduras.png" style="margin-top:10px" class="flag"/>
                    <p class="p-1 text-muted text-justify lh">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                    </p>
                  </div>
                </div>
                <div fxFlex="20" fxFlex.lt-sm="100" fxLayoutAlign=" center">
                  <button class="btn-ver" role="button">VER MÁS</button>
                </div>
              </div>
              <div *ngIf="i != (gurus.length -1); else templatePoint">
                <hr>
              </div>
              <ng-template #templatePoint></ng-template>
            </div>
          </mat-card>
      </div>
    </div>

  <!--</mat-card>-->
</div>
