<div class="content-search">
    <div fxLayout="column">
        <div>
            <span>Tiendas</span>
            <div class="search-info">
                <div *ngIf="this.storeList && this.storeList.length === 0 ;else infoStores" class="message">No hay datos que mostrar</div>
                <ng-template #infoStores>
                    <ul class="message-list">
                        <li *ngFor="let store of storeList" (click)="selectSearch(store)" [routerLink]="['/store', store.nombre, store.id]">
                            <img [src]="this.plazzaService.getPathImageStore(store.id)" onerror="this.src='assets/images/others/not-found.png';"/>
                            {{store.nombre}}
                        </li>
                    </ul>
                </ng-template>
            </div>
        </div>
        <div>
            <span>Categorías</span>
            <div class="search-info">
                <div *ngIf="this.categoryList && this.categoryList.length === 0;else infoCategories" class="message">No hay datos que mostrar</div>
                <ng-template #infoCategories>
                    <ul class="message-list">
                        <li *ngFor="let category of categoryList" (click)="selectSearch(category)" [routerLink]="['/products', category.nombre, 'category', category.id]">
                            <img [src]="this.plazzaService.getPathImageCategory(category.id)" onerror="this.src='assets/images/others/not-found.png';"/>
                            {{category.nombre}}
                        </li>
                    </ul>
                </ng-template>
            </div>
        </div>
        <div>
            <span>Productos</span>
            <div class="search-info">
                <div *ngIf="this.productList && this.productList.length === 0;else infoProducts" class="message">No hay datos que mostrar</div>
                <ng-template #infoProducts>
                    <ul class="message-list">
                        <li *ngFor="let product of productList" (click)="selectSearch(product)" [routerLink]="['/products', product.id, product.nombre]">
                            <img [src]="this.plazzaService.getPathImageProduct(product.id)" onerror="this.src='assets/images/others/not-found.png';"/>
                            {{product.nombre}}
                        </li>
                    </ul>
                </ng-template>
            </div>
        </div>
    </div>
</div>