import { Component, OnInit, HostListener, ViewChild, Inject, PLATFORM_ID } from '@angular/core'; 
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Settings, AppSettings } from '../app.settings';
import { PlazzaService } from '../services/plazza.service';
import { Category, CategoryPlazza } from '../app.models';
import { SidenavMenuService } from '../theme/components/sidenav-menu/sidenav-menu.service';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from '../services/auth.service';
import { Subscription } from 'rxjs';
import { ProfileService } from '../services/cliente/profile.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [ SidenavMenuService ]
})
export class PagesComponent implements OnInit {

  viewHeader = true
  message = null

  public showBackToTop:boolean = false; 
  public category:CategoryPlazza;
  public superCategories:CategoryPlazza[];
  public superCategory: CategoryPlazza;
  public sidenavMenuItems:Array<any>;
  @ViewChild('sidenav', { static: true }) sidenav:any;

  public settings: Settings;

  obs: Subscription[] = []
  user = null
  profile_user = null

  search = ''  
  showSearch = false

  filter = {
    search: null,
    superCategory: null,
    category: null,
    subCategory: null,
  }

  constructor(
    public appSettings:AppSettings, 
    public plazzaService:PlazzaService, 
    public sidenavMenuService:SidenavMenuService,
    private authService: AuthService,
    private profileService: ProfileService,
    public router:Router,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object) { 
    this.settings = this.appSettings.settings; 
    this.obs.push(
      this.authService.currentUser.subscribe(res => {
        this.user = res == null ? null : res
        if(res && res.id_sesion){
          this.getNameUser()
        }
      })
    )
  }

  public ngDoCheck() {
    
    if(this.router.url.substring(0,9) == "/products"){
      this.showSearch = true
      this.activatedRoute.queryParams.subscribe(params => {

        //this.search = null

        for (const key in params) {
          if (params[key] !== undefined) {
            this.filter[key] = params[key]
          }
        }
      });
      

      /*if(this.filter.search != null && this.filter.search != ""){
        this.search = this.filter.search
      }*/
      /*if(this.filter.superCategory != "" || this.filter.superCategory != undefined){
        this.superCategory = this.changeSuperCategoryRouter(this.filter.superCategory)
      }
      if(this.filter.search != "" || this.filter.search != undefined){
        this.search = this.filter.search
      }*/
    }else{
      this.showSearch = false
    }
  }

  ngOnInit() {
    //this.search = null
    this.ngDoCheck();
    //this.search = this.filter.search


    this.getMessageHeader();
    this.getSuperCategories();
    this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
    setTimeout(() => {
      this.settings.theme = 'plazza'; 
    });
  } 

  getMessageHeader(){
    this.plazzaService.getConfiguration().subscribe((data:any)=>{
      this.message = data.mensaje ? data.mensaje : null;
    })
  }

  public getSuperCategories(){    
    this.plazzaService.getSupercategories().subscribe((data:any) => {
      let ninguno = {id:0, nombre:'Ninguno', tipo: 1}
      this.superCategories = data.filter(ele => ele.tipo == 1);
      if(Array.isArray(this.superCategories) && this.superCategories.length > 0) {
        this.superCategories.unshift(ninguno)
      }
      this.superCategory = this.superCategories[0];
      //this.plazzaService.Data.categories = data;
    })
  }

  public changeSuperCategory(event){
    if(event.target){
      this.superCategory = this.superCategories.filter(category => category.nombre == event.target.innerText)[0];
    }
    if(window.innerWidth < 960){
      this.stopClickPropagate(event);
    } 
  }

  changeSuperCategoryRouter(element){
    if(Array.isArray(this.superCategories) && this.superCategories.length > 0){
      return this.superCategories.find(category => category.id == element)
    }
  }

  public remove(product) {
      const index: number = this.plazzaService.Data.cartList.indexOf(product);
      if (index !== -1) {
          this.plazzaService.Data.cartList.splice(index, 1);
          this.plazzaService.Data.totalPrice = this.plazzaService.Data.totalPrice - product.newPrice*product.cartCount;
          this.plazzaService.Data.totalCartCount = this.plazzaService.Data.totalCartCount - product.cartCount;
          this.plazzaService.resetProductCartCount(product);
      }        
  }

  public clear(){
    this.plazzaService.Data.cartList.forEach(product=>{
      this.plazzaService.resetProductCartCount(product);
    });
    this.plazzaService.Data.cartList.length = 0;
    this.plazzaService.Data.totalPrice = 0;
    this.plazzaService.Data.totalCartCount = 0;
  }
 

  public changeTheme(theme){
    this.settings.theme = theme;       
  }

  public stopClickPropagate(event: any){
    event.stopPropagation();
    event.preventDefault();
  }

  public onSearch(){
    console.log('search', this.search)
    this.router.navigate(['/products'], { queryParams: { search: this.search } })
    this.showSearch = true
  }

  clearSearch() {
    this.search = ''
    this.onSearch()
  }
 
  onUpdateSearch(event){
    if(event){
      this.search = event.nombre
    }
  }

  public scrollToTop(){
    var scrollDuration = 200;
    var scrollStep = -window.pageYOffset  / (scrollDuration / 20);
    var scrollInterval = setInterval(()=>{
      if(window.pageYOffset != 0){
         window.scrollBy(0, scrollStep);
      }
      else{
        clearInterval(scrollInterval); 
      }
    },10);
    if(window.innerWidth <= 768){
      setTimeout(() => { 
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0,0);
        }  
      });
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);   
    let header_toolbar = document.getElementById('header-toolbar'); 
    if(header_toolbar){ 
      if(scrollTop >= header_toolbar.clientHeight) {
        this.settings.mainToolbarFixed = true;
      }
      else{
        if(!document.documentElement.classList.contains('cdk-global-scrollblock')){
          this.settings.mainToolbarFixed = false;
        }        
      } 
    } 
    else{
      this.settings.mainToolbarFixed = true;
    }  
    ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;  
  }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) { 
        this.sidenav.close(); 
      }                
    });
    this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());
  }

  public closeSubMenus(){
    if(window.innerWidth < 960){
      this.sidenavMenuService.closeAllSubMenus();
    }    
  }

  closeHead(){
    this.viewHeader = false
  }

  getNameUser(){
    this.profileService.getProfile(this.user.id_sesion).subscribe(data => {
      this.profile_user = data
    }, error => {
      console.log("error",error)
    })
  }

  logout(){
    this.authService.logout()
  }

  ngOnDestroy() {
    this.obs.forEach(element => {
      element.unsubscribe()
    });
  }

  openPage(data){
    if(data == 'guru'){
      this.router.navigate(['/registrar/P']);
    }
    if (data == 'cliente'){
      this.router.navigate(['/registrar']);
    }
  }
}