import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.ssr';
import { AuthService } from '../auth.service';

const URL_API = environment.domain

@Injectable({
  providedIn: 'root'
})
export class MethodPayService {

    constructor(public http: HttpClient, private authService: AuthService) { }

    headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    })

    verifyBasa(session:any) {
        let auxheaders: HttpHeaders = new HttpHeaders({
        'sesion': session
        })

        const url_api = URL_API + 'verificar_estado_basa/verificar_estado_basa.php'
        return this.http.get(url_api, { headers: auxheaders });
    }
    
    sendValidateBasa(session:any,data){
        let auxheaders: HttpHeaders = new HttpHeaders({
        'sesion': session
        })

        const url_api = URL_API + 'validar_transaccion_basa/validar_transaccion_basa.php'
        return this.http.post(url_api, data, { headers: auxheaders });
    }
    
    validateDilo(session:any,data){
        let auxheaders: HttpHeaders = new HttpHeaders({
        'sesion': session
        })
        
        const url_api = URL_API + 'verificar_dilo/verificar_dilo.php'
        return this.http.post(url_api, data, { headers: auxheaders });
    }

    cancelDilo(session:any,data){
        let auxheaders: HttpHeaders = new HttpHeaders({
        'sesion': session
        })
        
        const url_api = URL_API + 'cancelar_dilo/cancelar_dilo.php'
        return this.http.post(url_api, data, { headers: auxheaders });
    }

    payCarritoDilo(session:any,data){
        let auxheaders: HttpHeaders = new HttpHeaders({
        'sesion': session
        })
        
        const url_api = URL_API + 'compra_directa_dilo/compra_directa_dilo.php'
        return this.http.post(url_api, data, { headers: auxheaders });
    }

    payCotizacionDilo(session:any,data){
        let auxheaders: HttpHeaders = new HttpHeaders({
        'sesion': session
        })
        
        const url_api = URL_API + 'compra_cotizacion_dilo/compra_cotizacion_dilo.php'
        return this.http.post(url_api, data, { headers: auxheaders });
    }

    getAccountsBank(){
        const url_api = URL_API + 'get_cuentas_banco/get_cuentas_banco.php'
        return this.http.get(url_api, { headers: this.headers });
    }

    payTransferencia(session:any,data){
        let auxheaders: HttpHeaders = new HttpHeaders({
        'sesion': session
        })
        
        const url_api = URL_API + 'transferencia_cotizacion/transferencia_cotizacion.php'
        return this.http.post(url_api, data, { headers: auxheaders });
    }
}