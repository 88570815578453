<footer class="bg-primary footer" style="background-color: #707170 !important;">
    <div fxLayout="row wrap" fxLayoutAlign="space-between start center" class="py-3 mx-3 border-bottom-mute theme-container cat-cen">  
        
        <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="20" ngClass.xs=""> 
            <h3 class="col-title">Nuestra Empresa</h3>
            <p class="mt-1"><a routerLink="/nosotros" class="link secondary-color">Quiénes Somos</a></p>
            <p class="mt-1"><a routerLink="/como-funciona" class="link secondary-color">Cómo Funciona</a></p>
            <p class="mt-2"><a routerLink="/alianzas-estratégicas" class="link secondary-color">Alianzas Estratégica</a></p>
            <p class="mt-2"><a routerLink="/faq" class="link secondary-color">Preguntas Frecuentes</a></p>
            <p class="mt-1"><a routerLink="/contact" class="link secondary-color">Contáctanos</a></p>
            <p class="mt-1"><a routerLink="/fundacion" class="link secondary-color">Fundación Gurús</a></p>
            <p class="mt-1"><a routerLink="/consulta-asesor" class="link secondary-color">Consejo Asesor</a></p>
            <p class="mt-1"><a routerLink="/terminos-condiciones" class="link secondary-color">Términos y Condiciones</a></p>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="20" ngClass.xs="" *ngIf="this.user != null"> 
            <h3 class="col-title">Mi Cuenta</h3>
            <p class="mt-2"><a routerLink="/cuenta/points" class="link secondary-color">Mis Puntos</a></p>
            <p class="mt-1"><a routerLink="/cuenta/orders" class="link secondary-color">Mi Órdenes</a></p>
            <p class="mt-1"><a routerLink="/cuenta/quotes" class="link secondary-color">Mis Cotizaciones</a></p>
            <p class="mt-1"><a routerLink="/cuenta/message" class="link secondary-color">Mensajes</a></p>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="20" ngClass.xs=""> 
            <h3 class="col-title">Enlaces de Interés</h3>
            <p class="mt-1"><a routerLink="/" class="link secondary-color">- Gurús Empresas</a></p>
            <p class="mt-1"><a routerLink="/" class="link secondary-color">- Gurús Digitales</a></p>
            <p class="mt-2"><a routerLink="/" class="link secondary-color">- Gurús Hogar</a></p>
            <p class="mt-2"><a routerLink="/" class="link secondary-color">- Gurús Oficios</a></p>
            <p class="mt-2"><a routerLink="/" class="link secondary-color">- Gurús Juniors</a></p>
            <p class="mt-1"><a routerLink="/" class="link secondary-color">- Central de Empleos</a></p>
            <p class="mt-1"><a routerLink="/" class="link secondary-color">- Gurús Jubilados</a></p>
            <p class="mt-1"><a routerLink="/" class="link secondary-color">- Super Gurús</a></p>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="30"> 

            <h3 class="col-title">Síguenos</h3>        
            <div fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <ul class="social mt-1">
                    <li>
                        <a (click)="openLink(plazza?.facebook)">
                            <svg class="social-icon" viewBox="0 0 24 24"><path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"/></svg>
                        </a>
                    </li>
                    <li>
                        <a (click)="openLink(plazza?.instagram)">
                            <svg class="social-icon" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                        </a>
                    </li>
                    <li>
                        <a (click)="openLink(plazza?.linkedin)">
                            <svg class="social-icon" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg>
                        </a>
                    </li>
                </ul>
            </div>
            <br><br>
            <h3 class="col-title">Suscríbete a nuestra página</h3>
            <!--<p fxLayout="row" fxLayoutAlign="start center" class="mt-2 secondary-color" style="text-align: justify;">
                Ingrese su dirección de correo electrónico a continuación para suscribirse a nuestro boletín y mantenenerse actualizado con descuentos y ofertas especiales.
            </p>-->
            <form method="get" class="subscribe-search-form" class="mt-1" ngClass.sm="mt-2" ngClass.xs="mt-2">
                <input type="text" placeholder="Ingresa tu correo aquí" fxFlex class="input-email">
                <button mat-raised-button  (click)="subscribe()" type="button" class="mat-elevation-z0 text-muted" style="margin-right: 30px !important;"><mat-icon> mail_outline</mat-icon></button>
            </form>

            <br><br>
            <h3 class="col-title">Métodos de pago</h3>
            <p ngClass.xs="mt-1">
                <img src="assets/images/payment/visa.png"  style="width: 50px; height: 35px;" class="payment-logo noselect" alt="Visa" />
                <img src="assets/images/payment/mastercard.png" style="width: 50px; height: 35px;" class="payment-logo noselect" alt="MasterCard" />
                <!--<img src="assets/images/payment/amex.jpg" style="width: 50px; height: 22px;" class="payment-logo noselect" alt="Amex" />-->
                <img src="assets/images/payment/dilo.png" style="width: 50px; height: 22px;" class="payment-logo noselect" alt="Dilo" />

                
                <!--<img src="assets/images/payment/BAC.jpg" style="width: 50px; height: 22px;" class="payment-logo noselect" alt="Bac" />
                <img src="assets/images/payment/paypal.png" style="width: 50px; height: 22px;" class="payment-logo noselect" alt="Paypal" />-->
    
            </p>

        </div>
        <!--<div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="30" ngClass.xs="mt-2" class="contact-information"> 
            <h3 class="col-title">Síguenos</h3>            
            <p fxLayout="row" fxLayoutAlign="start center" class="margin-footer secondary-color" style="text-align: justify; font-size: 14px;">
                <span>Hola, siempre estamos abiertos a la cooperación y sugerencias, contáctenos de una de las formas a continuación.</span>
            </p>
            <div fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <ul class="social mt-1">
                    <li>
                        <a (click)="openLink(plazza?.facebook)">
                            <svg class="social-icon" viewBox="0 0 24 24"><path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"/></svg>
                        </a>
                    </li>
                    <li>
                        <a (click)="openLink(plazza?.instagram)">
                            <svg class="social-icon" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                        </a>
                    </li>
                    <li>
                        <a (click)="openLink(plazza?.linkedin)">
                            <svg class="social-icon" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    -->
        
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="copyright secondary-color theme-container">
        <!--<p ngClass.xs="mt-1"><a mat-button href="https://www.gurushonduras.com/" target="_blank"> <img src="assets/images/gurus_logo.png" class="logo-footer" alt="Gurus" /> </a> </p>-->
        <p ngClass.xs="mt-1"><a mat-button href="https://www.gurushonduras.com/" target="_blank">Grupo Gurús S.A. </a> </p>

        <!--<p ngClass.xs="mt-1"></p><a mat-button href="https://www.albatrosservices.com/" target="_blank">Albatros Services</a> </p>-->
        <p ngClass.xs="mt-1">
            <!--<img src="assets/images/payment/amex.jpg" style="width: 50px; height: 22px;" class="payment-logo noselect" alt="Amex" />
            <img src="assets/images/payment/visa.png"  style="width: 50px; height: 35px;" class="payment-logo noselect" alt="Visa" />
            <img src="assets/images/payment/BAC.jpg" style="width: 50px; height: 22px;" class="payment-logo noselect" alt="Bac" />
            <img src="assets/images/payment/paypal.png" style="width: 50px; height: 22px;" class="payment-logo noselect" alt="Paypal" />
            <img src="assets/images/payment/mastercard.png" style="width: 50px; height: 35px;" class="payment-logo noselect" alt="MasterCard" />
            -->
            <a mat-button href="https://albatrosservices.com/" target="_blank">© 2022 Albatros Services</a>
        </p>
    </div>
</footer>