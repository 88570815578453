<div>
  <!--
  <div id="gradient"></div>
  <form class="searchbox" >

    <input type="search" placeholder="Encuentra un Gurú ideal para la actividad que quieras realizar" />
    <button type="submit" value="search">&nbsp;</button>
    <div class="advance noselect pointer" (click)="changeAdvance()">
      <span class="advance-search">Búsqueda avanzada</span>
      <mat-icon class="icon-arrow" [@rotatedState]='state' color="#585858">keyboard_arrow_down</mat-icon>
    </div>
    <div *ngIf="advanceSearch">

      <div class="row">
        <div class="col">
          <span>País:</span>
          <select class="form-control">
            <option value="Hn">Honduras</option>
          </select>
        </div>
        <div class="col">
          <span>Departamento/Provincia:</span>
          <select class="form-control">
            <option value="Hn">Honduras</option>
          </select>
        </div>
        <div class="col">
          <span>Ciudad:</span>
          <select class="form-control">
            <option value="Hn">Honduras</option>
          </select>
        </div>
      </div>


      <div class="row sections">
        <div class="col">
          <div class="box">
            <input id="one" type="checkbox">
            <span class="check"></span>
            <label for="one">Gurús para Hogar</label>
          </div>
        </div>
        <div class="col">
          <div class="box">
            <input id="two" type="checkbox">
            <span class="check"></span>
            <label for="two">Gurús para Empresas</label>
          </div>
        </div>
        <div class="col">
          <div class="box">
            <input id="three" type="checkbox">
            <span class="check"></span>
            <label for="three">Gurús Digitales</label>
          </div>
        </div>
      </div>

    </div>

    
  </form>
-->

<!--<div fxLayout="row" fxLayoutAlign="space-between center" class="info-header p-1" *ngIf="viewHeader">
  <span fxFlex="90" fxLayoutAlign="center">¡CONTRATA YA TU GURÚ!</span>
  <button fxLayoutAlign="right" mat-icon-button style="color: white;" (click)="closeHead()">
      <mat-icon>close</mat-icon>
  </button>
</div>  -->

  <div class="main-banner">
    <video *ngIf="true" autoplay loop playsinline [muted]="true" onloadedmetadata="this.muted = true" class="video-background">
      <source src="./../../../assets/video/memory-of-a-woman.mp4" type="video/mp4">
      <p>Your browser does not support the video element.</p>
    </video>

    <div class="box_title_content">
      <div class="box_home">
        <div class="box_search">
          <p class="title_text">¡CONTRATA YA TU GURÚ!</p>
          <form fxLayout="column" fxLayoutAlign="center center" [formGroup]="location"> 
            <div
              [class.flex-form] = "advanceSearch == false"
              [class.flex-form-2] = "advanceSearch == true">
              <label style="border-radius: 9px 0 0 9px;">
                &nbsp;&nbsp;
              </label>
              
              <!--<input type="searc" placeholder="">-->
              <!--<mat-form-field class="list-super-categories">
                <mat-label >Super Categorias</mat-label>
                <mat-select formControlName="superCategory" (selectionChange)="changeSelectSuperCategory($event)">-->
                  <!--<mat-option>
                    <ngx-mat-select-search 
                      noEntriesFoundLabel="{{'SELECT.ZERO_RECORDS' | translate}}">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngIf="!nothingDepart" [value]="'ALL'">
                    Todos
                  </mat-option>-->
                  <!--<mat-option *ngFor="let item of listFilterSuperGurus" [value]="item.id">
                    {{item.nombre}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div style="background-color: #707170 ; padding: 0.1px; font-size: 3px;">
                &nbsp;
              </div>-->
              <input type="search" [ngModelOptions]="{standalone: true}" placeholder="Encuentra un Gurú ideal para la actividad que quieras realizar"  [(ngModel)]="search" fxFlex>
              <input type="submit" value="" (click)="onSearch()" class="btn-search">
              <!--<span class="btn-adv" (click)="changeAdvance()"><mat-icon class="icon-arrow" [@rotatedState]='state' color="#585858">keyboard_arrow_down</mat-icon></span>-->
            </div>
            <div *ngIf="advanceSearch" class="advancebox">
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field appearance="fill" class="form-location">
                    <mat-label>Pais</mat-label>
                    <mat-select name="country" formControlName="country" >
                      <mat-option>
                        <ngx-mat-select-search 
                          noEntriesFoundLabel="{{'SELECT.ZERO_RECORDS' | translate}}" [placeholderLabel]="'Buscar...'">
                        </ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let country of countries" [value]="country">{{country}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field appearance="fill" class="form-location">
                    <mat-label>Departamento/Provincia</mat-label>
                    <mat-select name="department" formControlName="department">
                      <mat-option>
                        <ngx-mat-select-search 
                          noEntriesFoundLabel="{{'SELECT.ZERO_RECORDS' | translate}}" [placeholderLabel]="'Buscar...'">
                        </ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let department of deparments" [value]="department">{{department}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field appearance="fill" class="form-location">
                    <mat-label>Ciudad</mat-label>
                    <mat-select name="municipality" formControlName="municipality">
                      <mat-option>
                        <ngx-mat-select-search 
                          noEntriesFoundLabel="{{'SELECT.ZERO_RECORDS' | translate}}" [placeholderLabel]="'Buscar...'">
                        </ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let municipality of municipalities" [value]="municipality">{{municipality}}</mat-option>
                    </mat-select>
                  </mat-form-field>
      
                </div>
              </div>
            </div>
              
          </form>
        </div>
        <div class="container">
    
        </div>
      </div>
    </div>
  </div>
  



  
  
  <!--
    <div class="box_title_content">
    <div class="box_home">
      <div class="container"></div>
    </div>
  </div>
  <div class="container">
    <form fxLayout="column" fxLayoutAlign="center center" [formGroup]="location"> 
  -->
</div>
  

<app-tab-gurus [dispatchs]="dispatchs" [supercategory]= "this.location.value.superCategory" (returnSuper)="indexSuper($event)"></app-tab-gurus>