import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CategoryPlazza } from 'src/app/app.models';
import { PlazzaService } from 'src/app/services/plazza.service';

@Component({
  selector: 'app-tab-gurus',
  templateUrl: './tab-gurus.component.html',
  styleUrls: ['./tab-gurus.component.scss']
})
export class TabGurusComponent implements OnInit {

  @Input('dispatchs') dispatchs: any = [];
  //@Input('supercategory') supercategory: any = [];
  @Output() returnSuper = new EventEmitter<any>();
  
  public supercategories = [];
  public categories:CategoryPlazza[];

  selectedTabIndex = 0
  idSupercategory = 1

  super_categories:any = [
    { img: "gurus_empresa.jpg", name: "", description: "Gurús para Empresa", rating: 0},
    { img: "gurus_digitales.jpg", name: "", description: "Gurús Digitales", rating: 0},
    { img: "gurus_hogar.jpg", name: "", description: "Gurús para Hogar", rating: 0},
    { img: "gurus_oficios.png", name: "", description: "Gurús Oficios", rating: 5 },
  ] 

  @Input() 
  public set supercategory(supercategory: any ) {
    this.idSupercategory = supercategory
    if(this.idSupercategory == null){
      this.idSupercategory = 1
      this.getCategories(this.idSupercategory, 1, 15) // id, página, cantidad
    }else{
      this.getTab(this.idSupercategory)
    }
  }

  ngOnChanges(changes: SimpleChanges) {        
    //this.doSomething(changes.categoryId.currentValue);    
  }


  constructor(
    public plazzaService:PlazzaService,
    public snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) { }

  gurus_hogar: any = [
    { link: "", name: "Alimentos y Bebidas"},
    { link: "", name: "Alquiler de Amigo"},
    { link: "", name: "Arquitectura y Diseño"},
    { link: "", name: "Arte y Manualidad"},
    { link: "", name: "Articulos Promocionales"},
    { link: "", name: "Asesoria de Viajes"},
    { link: "", name: "Audios"},
    { link: "", name: "Automoviles"},
    { link: "", name: "Baile"},
    { link: "", name: "Belleza"},
    { link: "", name: "Bienes y Raices"},
    { link: "", name: "Biomédicos"},
    { link: "", name: "Bioseguridad"},
  ]

  gurus_empresas: any = [
    { link: "", name: "Alimentos y Bebidas" },
    { link: "", name: "Arquitectura y Diseño" },
    { link: "", name: "Articulos Promocionales" },
    { link: "", name: "Asesoria de Viajes" },
    { link: "", name: "Asistentes y Secretarías" },
    { link: "", name: "Audios" },
    { link: "", name: "Auditorías" },
    { link: "", name: "Automóviles" },
    { link: "", name: "Bienes y Raices" },
    { link: "", name: "Biomédicos" },
    { link: "", name: "Bioseguridad" },
    { link: "", name: "Blindajes" },
  ]

  gurus_digitales: any = [
    { link: "", name: "Audios"},
    { link: "", name: "Comerrcio Electrónico"},
    { link: "", name: "Correos Electrónicos"},
    { link: "", name: "Dibujo / Caricatura"},
    { link: "", name: "Diseño Gráfico"},
    { link: "", name: "Edición de Imagen"},
    { link: "", name: "Fotografía"},
    { link: "", name: "Gaming y Streaming"},
    { link: "", name: "Libro y E-book"},
    { link: "", name: "Mercadeo Digital"},
    { link: "", name: "Móvil"},
    { link: "", name: "Musica"},
  ]

  tab_1: boolean = true;
  tab_2: boolean = false;
  tab_3: boolean = false;

  optionPaginate = {
    totalItems: 0,
    totalPages: 0,
    itemPerPage: 0,
    currentPage: 0
  }

  ngOnInit(): void {
    //this.getSuperCategories()
  }


  getSuperCategories(){
    this.plazzaService.getSupercategories().subscribe((data: any) => {
      this.supercategories = data.filter(ele => ele.tipo == 1)
      this.getCategories(this.supercategories[0].id, 1, 15)
    },error => {
      this.snackBar.open(error.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    })
  }

  getCategories(element, number, cantidad){   
    let categor = new FormData()
    categor.append('numero_pagina', number)
    categor.append('cantidad_x_pagina', cantidad) 
    categor.append('id_supercategoria', element)
    this.plazzaService.getCategoriesList(categor).subscribe((data:any) => {
      this.categories = data.data;
      
      this.optionPaginate.totalItems = data.total
      this.optionPaginate.totalPages = data.paginas
      this.optionPaginate.itemPerPage = cantidad
      this.optionPaginate.currentPage = number

      this.plazzaService.Data.categories = data.data;
      this.cdr.detectChanges()
    }, error => {
      this.cdr.detectChanges()
      this.snackBar.open(error.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });    })
  }

  tabChanged(e) {
    this.selectedTabIndex = e
    this.returnSuper.emit(this.supercategories[e].id) //el id de la supercategoria
  }

  getTab(element){
    switch(element){
      case 1:{
        this.dispatchs.hogar = true;
        this.dispatchs.empresa = false;
        this.dispatchs.digital = false;
        break;
      }
      case 2:{
        this.dispatchs.hogar = false;
        this.dispatchs.empresa = true;
        this.dispatchs.digital = false;
        break;
      }
      case 3:{
        this.dispatchs.hogar = false;
        this.dispatchs.empresa = false;
        this.dispatchs.digital = true;     
        break;
      }
      default: {
        break;
      }
    }

    this.idSupercategory = Number(element)
    this.optionPaginate.currentPage = 1
    this.optionPaginate.itemPerPage = 15
    this.getCategories(this.idSupercategory,this.optionPaginate.currentPage, this.optionPaginate.itemPerPage)
    this.returnSuper.emit(this.idSupercategory)
  }

  get30Caracteres(text: string) {
    let nt = text
    let nu = text.length
    if (nu > 30) {
      return nt.substring(0, 30) + '...'
    } else {
      return nt
    }
  }

  onPageChanged(element){
    this.optionPaginate.currentPage = element;
    this.getCategories(this.idSupercategory,this.optionPaginate.currentPage, this.optionPaginate.itemPerPage)
  }

  openPage(){
    this.router.navigate(['/products']);
  }
}
