import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Category, CategoryPlazza, Product, ProductPlazza } from './../app.models';
import { environment } from 'src/environments/environment.ssr';

export class Data {
    constructor(public categories: CategoryPlazza[],
                public compareList: Product[],
                public wishList: Product[],
                public cartList: Product[],
                public totalPrice: number,
                public totalCartCount: number) { }
}

@Injectable()
export class PlazzaService {
    public Data = new Data(
        [], // categories
        [], // compareList
        [],  // wishList
        [],  // cartList
        null, //totalPrice,
        0 //totalCartCount
    )
    
    public url = '/assets/data/'; 
    domain = environment.domain
    url_s3 = environment.url_amazon_s3

    constructor(public http:HttpClient, public snackBar: MatSnackBar) { }
    
    headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    })

    public getCategories(): Observable<Category[]>{
        return this.http.get<Category[]>(this.url + 'categories.json');
    }
   
    public getProducts(type): Observable<Product[]>{        
        return this.http.get<Product[]>(this.url + type + '-products.json');
    }

    public getProductById(id): Observable<Product>{
        return this.http.get<Product>(this.url + 'product-' + id + '.json');
    }

    //Main
    public getSupercategories(){
        let url = this.domain + 'supercategorias/supercategorias.php'
        return this.http.get(url,{ headers: this.headers });
    }

    public getTopStoreList(){ //Destacadas
        let url = this.domain + 'tiendas_top/tiendas_top.php'
        return this.http.get(url,{ headers: this.headers });
    }

    public getCategoriesList(data): Observable<CategoryPlazza[]>{
        let url = this.domain + 'get_categorias/get_categorias.php'
        return this.http.post<CategoryPlazza[]>(url,data);
    }

    public getProductsTop(): Observable<ProductPlazza[]>{
        let url = this.domain + 'get_productos_top/get_productos_top.php'
        return this.http.get<ProductPlazza[]>(url)
    }

    public getProductsNuevos(): Observable<ProductPlazza[]>{
        let url = this.domain + 'get_productos_new/get_productos_new.php'
        return this.http.get<ProductPlazza[]>(url)
    }

    public getStoreList(data){
        let url = this.domain + 'todas_tiendas/todas_tiendas.php'
        return this.http.post(url, data);
    }
    
    public getAllProducts(registro): Observable<ProductPlazza[]>{
        let url = this.domain + 'get_productos_all/get_productos_all.php'
        return this.http.post<ProductPlazza[]>(url, registro)
    }

    public getCategoryByStore(id_store){
        let url = this.domain + 'categorias_x_tienda/categorias_x_tienda.php'
        return this.http.post(url,id_store);
    }

    public getProductoByCategoryStore(data): Observable<ProductPlazza[]>{
        let url = this.domain + 'productos_x_categorias_x_tienda/productos_x_categorias_x_tienda.php'
        return this.http.post<ProductPlazza[]>(url,data);
    }

    public getProductsByCategory(id_category): Observable<ProductPlazza[]>{
        let url = this.domain + 'get_productos_x_categorias/get_productos_x_categorias.php'
        return this.http.post<ProductPlazza[]>(url,id_category);
    }

    public getProductDetail(id): Observable<ProductPlazza>{
        let url = this.domain + 'producto/producto.php'
        return this.http.post<ProductPlazza>(url,id)
    }

    public getMetodoEnvioByProduct(data){
        let url = this.domain + 'metodos_x_producto/metodos_x_producto.php'
        return this.http.post(url,data)
    }

    public getGurusXCategoria(data){
        let url = this.domain + 'get_gurus_x_categoria/get_gurus_x_categoria.php'
        return this.http.post(url,data)
    }

    public getCategoriasXGuru(data){
        let url = this.domain + 'categorias_x_guru/categorias_x_guru.php'
        return this.http.post(url,data)
    }

    public getSubcategoriasXCategoria(data){
        let url = this.domain + 'get_subcategoria_x_categoria/get_subcategoria_x_categoria.php'
        return this.http.post(url,data)
    }

    public getGurusXSubcategoria(data){
        let url = this.domain + 'get_gurus_x_subcategoria/get_gurus_x_subcategoria.php'
        return this.http.post(url,data)
    }

    public searchSubCategorias(data){
        let url = this.domain + 'subcategorias_busqueda/subcategorias_busqueda.php'
        return this.http.post(url,data)
    }
    
    public searchGurus(data){
        let url = this.domain + 'get_gurus/get_gurus.php'
        return this.http.post(url,data)
    }

    public searchServicios(data){
        let url = this.domain + 'servicio_busqueda/servicio_busqueda.php'
        return this.http.post(url,data)
    }

    //búsqueda
    public searchStore(data){
        let url = this.domain + 'tiendas_busqueda/tiendas_busqueda.php'
        return this.http.post(url,data);
    }

    public searchCategory(data){
        let url = this.domain + 'categorias_busqueda/categorias_busqueda.php'
        return this.http.post(url,data);
    }

    public searchProduct(data){
        let url = this.domain + 'productos_busqueda/productos_busqueda.php'
        return this.http.post(url,data);
    }

    //Detail Guru
    public getDetailGuru(data){
        let url = this.domain + 'perfil_guru/perfil_guru.php'
        return this.http.post(url,data);
    }
    
    public getServicioXCategoriaXGuru(data){
        let url = this.domain + 'productos_x_categoria_x_guru/productos_x_categoria_x_guru.php'
        return this.http.post(url,data);
    }

    public getBanners(): Observable<any[]>{
        return this.http.get<any[]>(this.url + 'banners.json');
    }

    public getConfiguration(){
        return this.http.get(this.url + 'configuration.json');
    }

    public addToCart(product:Product){
        let message, status;        
       
        this.Data.totalPrice = null;
        this.Data.totalCartCount = null;

        if(this.Data.cartList.filter(item=>item.id == product.id)[0]){ 
            let item = this.Data.cartList.filter(item=>item.id == product.id)[0];
            item.cartCount = product.cartCount;  
        }
        else{           
            this.Data.cartList.push(product);
        }        
        this.Data.cartList.forEach(product=>{
            this.Data.totalPrice = this.Data.totalPrice + (product.cartCount * product.newPrice);
            this.Data.totalCartCount = this.Data.totalCartCount + product.cartCount;
        });

        message = 'El producto ' + product.name + ' ha sido agregado a tu carrito.'; 
        status = 'success';          
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    }

    public resetProductCartCount(product:Product){
        product.cartCount = 0;
        let compareProduct = this.Data.compareList.filter(item=>item.id == product.id)[0];
        if(compareProduct){
            compareProduct.cartCount = 0;
        };
        let wishProduct = this.Data.wishList.filter(item=>item.id == product.id)[0];
        if(wishProduct){
            wishProduct.cartCount = 0;
        }; 
    }

    public addToCompare(product:Product){
        let message, status;
        if(this.Data.compareList.filter(item=>item.id == product.id)[0]){
            message = 'El producto ' + product.name + ' ya ha sido agregado a la lista.'; 
            status = 'error';     
        }
        else{
            this.Data.compareList.push(product);
            message = 'El producto ' + product.name + ' ha sido agregado a la lista.'; 
            status = 'success';  
        }
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    }

    public addToWishList(product:Product){
        let message, status;
        if(this.Data.wishList.filter(item=>item.id == product.id)[0]){
            message = 'El producto ' + product.name + ' ya esta agregado a la lista.'; 
            status = 'error';     
        }
        else{
            this.Data.wishList.push(product);
            message = 'The product ' + product.name + ' ha sido agregado a la lista.'; 
            status = 'success';  
        }
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    }

    getPathImageCategory(id){
        let url = this.domain + 'img_profesiones/' + id + '.png'
        return url;
    }

    getPathImageStore(id){
        let url = this.domain + 'img_gurus/' + id + '.png'
        return url;
    }

    getPathImageProduct(id){
        let url = this.url_s3 + 'gurus/img_productos/' + id + '.png'
        return url;
    }

    getPathImageGuru(id){
        let url = this.url_s3 + 'gurus/img_gurus/' + id + '.png'
        return url;
    }

    getPathImageGuruCover(id){
        let url = this.url_s3 + 'gurus/img_portadas_gurus/' + id + '.png'
        return url;
    }

    getPathImageUrl(id){ //retornar solo el url menos domain
        let url = 'img_productos/' + id + '.png'
        return url;
    }

    getPathImageByLink(link){
        let url = this.domain + link
        return url;
    }

    //HOME JSON
    getPathImageAWS(link){
        let url = this.url_s3 + 'gurus/fe/' + link
        return url;
    }

    getPathImageAlianza(link){
        let url = this.url_s3 + 'gurus/fe/alianzas/' + link
        return url;
    }

    getPathImageConsultanAdvice(link){
        let url = this.url_s3 + 'gurus/fe/asesores/' + link
        return url;
    }

    getPathImageSuperCategory(link){
        let url = this.url_s3 + 'gurus/fe/superCategorias/' + link
        return url;
    }

    getPathImageGuruRecomendado(link){
        let url = this.url_s3 + 'gurus/fe/gurus_recomendados/' + link
        return url;
    }

    public getMonths(){
        return [
            { value: '01', name: 'January' },
            { value: '02', name: 'February' },
            { value: '03', name: 'March' },
            { value: '04', name: 'April' },
            { value: '05', name: 'May' },
            { value: '06', name: 'June' },
            { value: '07', name: 'July' },
            { value: '08', name: 'August' },
            { value: '09', name: 'September' },
            { value: '10', name: 'October' },
            { value: '11', name: 'November' },
            { value: '12', name: 'December' }
        ]
    }

    public getYears(){
        return ["2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030" ]
    }

    public getDeliveryMethods(){
        return [
            { value: 'free', name: 'Free Delivery', desc: '$0.00 / Delivery in 7 to 14 business Days' },
            { value: 'standard', name: 'Standard Delivery', desc: '$7.99 / Delivery in 5 to 7 business Days' },
            { value: 'express', name: 'Express Delivery', desc: '$29.99 / Delivery in 1 business Days' }
        ]
    }

} 