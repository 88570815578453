
<div fxLayout="column" fxLayoutAlign="center stretch" class="signup" fxLayoutGap="20px">
    <div>
        <p class="title-signup">Si buscas ofrecer tus servicios ¡Gurús es tu mejor aliado!
        </p>
        <p class="subtitle-signup">
            Miles de usuarios buscando tu perfil están esperándote.
        </p>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center start"  >
        <div fxFlexOffset="5" fxFlex="20">
            <img src="assets/images/signup.webp" class="img-signup" alt="singnup"/>
        </div>
        <div  FlexOffset="5">

            <div>
                <p class="title-list">Te ayudamos a ofrecer tus servicios.
            
                </p>
                <p class="subtitle-list">
                    Haz que tu perfil sea visible para miles de usuarios en nuestra bolsa de trabajo.
                </p>
            </div>

            <ol class="gradient-list">
                <li class="list-signup"><b>Registro gratuito.</b></li>
                <li class="list-signup"><b>Ofertas todos los días,</b> miles de usuarios buscando un gurú.</li>
                <li class="list-signup"><b>Alertas personalizadas,</b> notificando la necesidad de un servicio.</li>
                <li class="list-signup"><b>Completa tu perfil,</b> profesional y ganarás visibilidad.</li>
            </ol>
            
            
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" >
        <!--<button class="btn-signup">Regístrate gratis e inicia tu búsqueda</button>-->
        <!--<button (click)="openPage()">Crea tu cuenta gratis</button>-->
        <button class="custom-btn btn-3" (click)="openPage()"><span>Crea tu cuenta gratis</span></button>
    </div>
</div>