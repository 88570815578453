import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { MethodPayService } from 'src/app/services/both/methodPay.service';
import { ProfileService } from 'src/app/services/cliente/profile.service';

@Component({
  selector: 'app-basa-load-pay',
  templateUrl: './basa-load-pay.component.html',
  styleUrls: ['./basa-load-pay.component.scss']
})
export class BasaLoadPayComponent implements OnInit {

  previewData: any
  load: boolean = false


  state = 'LOAD' // 'ERROR' 'SUCCESS'
  interval;
  basaId;
  messageError = ''
  reference = ''
  total = ''
  max = 900000 //60000
  init = 0

  user = null
  session = null

  constructor(
    private profileService: ProfileService,
    private methodPayService: MethodPayService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<BasaLoadPayComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.basaId = this.data.basa.id_basa
    if(this.data.type == 'User'){
      this.user = this.authService.currentUserValue
    }else{
      this.user = this.authService.currentAdminValue
    }
    this.session = this.user ? this.user.id_sesion : null
  }

  ngOnInit() {
    this.openViewPay()
    this.interval = setInterval(() => {
      this.onGetLogOrder()
    }, 10000);
  }

  openViewPay(){
    try {
      let popupWin = window.open(this.data.basa.url, '_blank');
      //popupWin.document.write();
      popupWin.document.close();

    } catch (error) {

    }
  }

  onGetLogOrder() {
    let data = new FormData()
    data.append('idtb', this.basaId)

    this.methodPayService.sendValidateBasa(this.session,data).subscribe((res: any) => {

      /*Estado 0: sin respuesta
      Estado 1 Aprobado
      Estado 2: Cancelado/Rechazado
      Estado 3: Pendiente de Aprobación */

      if(res.estado == 1){
        this.state = 'SUCCESS'
        this.reference = res.referencia
        this.total = res.total
        clearInterval(this.interval)
        return
      }
      if(res.estado == 2){
        this.state = 'ERROR'
        this.messageError = res.respuesta
        this.reference = res.referencia
        this.total = res.total
        clearInterval(this.interval)
        return
      }
      if(res.estado == 3){
        this.state = 'PENDING'
        this.reference = res.referencia
        this.total = res.total
        clearInterval(this.interval)
        return
      }
      if(res.estado == 0){
        this.init = this.init + 10000
        if (this.init >= this.max) {
          this.state = 'EXPIRED'
          clearInterval(this.interval)
        }else{
          this.state = 'LOAD'
        }
        return
      }
    }, error => {

    })
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  closeSuccess() {
    this.dialogRef.close({state: this.state, referencia: this.reference})
  }
}
