import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up-free',
  templateUrl: './sign-up-free.component.html',
  styleUrls: ['./sign-up-free.component.scss']
})
export class SignUpFreeComponent implements OnInit {

  @Input('dispatchs') dispatchs: Array<any> = [];
  constructor(
    private router: Router
  ) {}

  ngOnInit(): void {

  }

  openPage(){
    this.router.navigate(['/registrar/P']);
  }

}
