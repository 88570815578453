<mat-dialog-content style="max-height: 57vh;">
    <div fxLayout="column">
        <div style="padding: 20px;">
            <div fxLayout="column">
                <div fxLayout="column" *ngIf="this.state == 'LOAD'" class="text-center">
                    <div fxFlex="100" style="text-align: -webkit-center;">
                        <mat-spinner [diameter]="70"></mat-spinner>
                    </div>
                    <div fxFlex="100" class="mt-10">
                        <h2>Procesando, en espera de transacción...</h2>
                    </div>
                </div>
                <div fxLayout="column" *ngIf="this.state == 'SUCCESS'" class="text-center">
                    <div fxFlex="100">
                        <mat-icon style="font-size: 73px; height: auto; width: auto; color:rgb(22, 185, 49)">check_circle</mat-icon>
                    </div>
                    <div fxFlex="100" class="mt-10">
                        <h4>Transacción con éxito.</h4>
                    </div>
                    <div fxFlex="100" class="mt-10 text-left">
                        <span>
                            <b>Fecha: </b> {{this.dateMessage}} <br>
                        </span>
                    </div>
                    <div fxFlex="100" class="mt-10">
                        <button color="primary" mat-raised-button (click)="closeSuccess()">ACEPTAR</button>
                    </div>
                </div>
                <div fxLayout="column" *ngIf="this.state == 'ERROR'" class="text-center">
                    <div fxFlex="100">
                        <mat-icon style="font-size: 73px; height: auto; width: auto;" color="warn">error</mat-icon>
                    </div>
                    <div fxFlex="100" class="mt-10">
                        <h4>{{this.messageError}}</h4>
                        <!--<h4>Transacción Cancelada/Rechazada</h4>-->
                    </div>
                    <div fxFlex="100" class="mt-10 text-left">
                        <span>
                            <b>Fecha: </b> {{this.dateMessage}} <br>
                        </span>
                    </div>
                    <div fxFlex="100" class="mt-10">
                        <button color="primary" mat-raised-button (click)="closeSuccess()">VOLVER A INTENTAR</button>
                    </div>
                </div>
                <div fxLayout="column" *ngIf="this.state == 'EXPIRED'" class="text-center">
                    <div fxFlex="100">
                        <mat-icon style="font-size: 73px; height: auto; width: auto;" color="warn">assignment_late</mat-icon>
                    </div>
                    <div fxFlex="100" class="text-center mt-10">
                        <h4>Tiempo de espera excedido, la transacción no fue procesado. Por favor verifique que su navegador no bloquee
                        ventanas emergentes.
                        </h4>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" fxFlex="100" class="mt-10">
                        <button ngClass.xs="mt-10" color="primary" mat-raised-button (click)="closeSuccess()">VOLVER A
                        INTENTAR</button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="this.state == 'LOAD'" class="mt-10 text-right">
            <button ngClass.xs="mt-10" color="primary" mat-raised-button (click)="closeCancelar()">Cancelar</button>
        </div>
    </div>
</mat-dialog-content>