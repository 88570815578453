import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorManagementService } from 'src/app/admin/services/system/error-management.service';
import { SnackBarService } from 'src/app/admin/services/system/snackbar.service';
import { DialogCustomComponent } from 'src/app/dialog-custom/dialog-custom.component';
import { ProfileService } from '../../../services/cliente/profile.service';

@Component({
  selector: 'app-quote-request',
  templateUrl: './quote-request.component.html',
  styleUrls: ['./quote-request.component.scss']
})
export class QuoteRequestComponent implements OnInit {

  public form: FormGroup

  service_id = ''
  files: any = {}

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<QuoteRequestComponent>, 
    private fb: FormBuilder,
    private profileService: ProfileService,
    private errorService: ErrorManagementService,
    private snackBarService: SnackBarService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
    
    ) {
      this.form = this.fb.group({
        comment: ['', Validators.compose([
        ])],
        file: ['', Validators.compose([
        ])]
      })
    }


  ngOnInit() {
    this.service_id = this.data.service
  }

  yes(element) {
    this.dialogRef.close({
      result: 'success',
      obj:{
        comment: this.form.value.comment,
        files: this.files
      }
    });
    //this.solicitarCotizacion();

  }
  no(element) {
    this.dialogRef.close();
  }

  solicitarCotizacion(){
    let send = {
      id_servicio: this.service_id,
    }

    if(this.form.value.comment != null){
      console.log(this.form.value.comment)
      let comentario = { comentario: this.form.value.comment }
      send = {...send, ...comentario }
    }

    /*if(this.form.value.file != null){
      let file = { comment: this.form.value.file }
      send = {...send, ...file }
    }*/

    console.log('datos de cotizacion', send)

    let dataP = new FormData()
    
    for(let key in send){  
      dataP.append(key,send[key])
    }

    this.profileService.solicitarCotizacion(dataP).subscribe((data:any)=>{
      if(data != null){
        this.snackBarService.success({ message: data.razon})
        this.router.navigate(['/cuenta/quotes']);
      }
      
    }, error =>{
      this.errorService.getErrorMessage(error)
    });
    
  }


  // Obtiene base64 y nombre de un archivo
  onChangeFile(files: any, type: string) {
    let file = files[0]
    if (file) {
      var blob = file.slice(0, file.size, file.type);
      let newName = file.name
      
      let newFile = new File([blob], `${newName}`, {type: file.type});

      const reader = new FileReader();
      reader.readAsDataURL(newFile);

      reader.onload = () => {
        this.files[type] = {
          base64: reader.result,
          fileName: `${newName}`,
        }
      };
    } else {
      delete this.files[type]
    }
  }
  
}
