<div class="modal-header">
  <h2 mat-dialog-title>{{data.title}}</h2>

  <button mat-icon-button class="modal-close-btn" (click)="this.dialogRef.close()">
    <mat-icon aria-label="label">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <p style="margin-top: 10px;">{{data.body}}</p>
</mat-dialog-content>

<mat-dialog-actions align="right">
  <button mat-raised-button (click)="no()">{{data.no}}</button>
  <button mat-raised-button (click)="yes()" color="primary">{{data.yes}}</button>
</mat-dialog-actions>