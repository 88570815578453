<div *ngIf="products">
    <div class="swiper-container" [swiper]="config">
        <div class="swiper-wrapper h-100">      
            <div *ngFor="let product of products" class="swiper-slide">
                <mat-card class="product-item">
                    <!--<mat-chip-list *ngIf="product?.tipo_promocion == 1">
                        <mat-chip color="warn" slected="true" *ngIf="product.descuento">{{product.descuento}}% Descuento</mat-chip> El servicio no trae porcentaje de descuento
                    </mat-chip-list> -->
                    <a  class="image-link">
                        <img [ngStyle]="{'height': height +'vh', 'object-fit': 'contain'}"
                         [src]="this.plazzaService.getPathImageProduct(product?.id)" 
                         onerror="this.src='assets/images/others/not-found.png';" class="swiper-lazy"/>
                        <!--<div class="swiper-lazy-preloader"></div>-->
                    </a>
                    <!--<h4 class="category text-muted">{{ ( plazzaService.Data.categories | filterById : product.categoryId )?.name }}</h4>-->                                                                     
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <a class="sub-category text-truncate primary-text">
                            {{product?.subcategoria}}
                        </a>
                        <!--<mat-icon color="warn" matTooltip="Por favor, ingresa al detalle del Producto para realizar la denuncia" >notification_important</mat-icon>-->
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <a class="title text-truncate primary-text">
                            {{product?.nombre}}
                        </a>
                        <!--<mat-icon color="warn" matTooltip="Por favor, ingresa al detalle del Producto para realizar la denuncia" >notification_important</mat-icon>-->
                    </div>
                    <div style="height:50px">
                        <p class="description custom-text block-with-text text-justify">
                            {{product?.descripcion}}
                        </p>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="prices">
                        <div fxLayout="column" fxLayoutAlign="center start">
                            <div *ngIf="product?.precio_fijo==1">
                                <div *ngIf="product?.precio_descuento != null && product?.precio_descuento != 0; else price">
                                    <p class="old-price text-muted"><span *ngIf="product.precio">{{product?.precio  | currency: 'L. ':"code"}}</span></p>
                                    <p class="new-price">{{product?.precio_descuento  | currency: 'L. ':"code"}}</p>    
                                </div>
                                <ng-template #price>
                                    <p class="new-price">{{product?.precio | currency: 'L. ':"code"}}</p>
                                </ng-template>
                            </div>
                            
                        </div>
                        <div fxLayout="column" fxLayoutAlign="center start">
                            <button class="button-7" *ngIf="product?.precio_fijo==1" role="button" (click)="onPayment(product)"><i class="fa fa-shopping-cart"></i> Comprar</button>
                            <button class="button-7" *ngIf="product?.precio_fijo!=1" role="button" (click)="openQuoteRequest(product)"><i class="fa fa-shopping-cart"></i> Cotizar</button>
    
                        </div>
                        <!--<app-rating [ratingsCount]="5" [ratingsValue]="product?.calificacion" [direction]="'column'"></app-rating>-->
                    </div>                            
                    <!--<div class="divider mt-2"></div>
                    <div class="icons">
                        <app-controls [product]="product" (onOpenProductDialog)="openProductDialog(product)"></app-controls>
                    </div>-->
                </mat-card>
            </div>

            <!--<div class="swiper-slide" *ngFor="let product of products" fxLayoutAlign="center stretch">
                <div class="product-card">
                    <div class="badge">Hot</div>
                    <div class="product-tumb">
                        <img src="{{domain}}img_productos/{{product?.id}}.png" onerror="this.src='assets/images/others/not-found.png';" alt="">
                    </div>
                    <div class="product-details" fxLayout="column" fxLayoutAlign="start stretch" >
                        <span class="product-catagory">{{product?.subcategoria}}</span>
                        <h4><a  class="service_name">{{product?.nombre}}</a></h4>
                        <p class="scroller">{{product?.descripcion}}</p>
                        <div>
                            <div class="product-bottom-details" fxLayout="row" fxLayoutAlign="start start">
                                <div class="product-price" fxFlex=50>
                                    <div *ngIf="product?.precio_fijo==1" fxFlex=100>
                                        <div *ngIf="product?.precio_descuento != null && product?.precio_descuento != 0; else price" >
                                            <small>{{product?.precio  | currency: 'L. ':"code"}}</small><br>
                                            {{product?.precio_descuento  | currency: 'L. ':"code"}}
                                        </div>
                                        <ng-template #price>
                                            {{product?.precio | currency: 'L. ':"code"}}
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="product-links" fxFlex=50>
                                    <a href=""><i class="fa fa-heart"></i></a>
                                    <a href=""><i class="fa fa-shopping-cart"></i></a>

                                    <button class="button-7" *ngIf="product?.precio_fijo==1" role="button" (click)="onPayment(product)"><i class="fa fa-shopping-cart"></i> Comprar</button>
                                    <button class="button-7" *ngIf="product?.precio_fijo!=1" role="button" (click)="openQuoteRequest(product)"><i class="fa fa-shopping-cart"></i> Cotizar</button>
    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            -->
            
            
            
        </div>
        <button mat-mini-fab class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
        <button mat-mini-fab class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button> 
    </div>
</div>