import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from './user';
import { environment } from 'src/environments/environment.ssr';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({ 
    providedIn: 'root' 
})
export class AuthService {
    private URL = ''
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private currentAdminSubject: BehaviorSubject<User>;
    public currentAdmin: Observable<User>;

    constructor(private http: HttpClient, private router: Router){
        this.URL = environment.domain
        this.currentUserSubject = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem('customerPlazza'))
          );
        this.currentUser = this.currentUserSubject.asObservable();

        this.currentAdminSubject = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem('customerAdminPlazza'))
          );
        this.currentAdmin = this.currentAdminSubject.asObservable();
    }

    headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'  //no acepta json al mandar form-data
    })

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public get currentAdminValue(): User {
        return this.currentAdminSubject.value;
    }

    login(data) {
        let url_api = this.URL + 'login/login.php'
        return this.http.post<any>(url_api, data)
        .pipe(map( (user: User)=> {
            if(user.id_sesion != -1){
                if(user.tipo == 1){ //cliente
                    localStorage.setItem('customerPlazza', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }else if(user.tipo == 2){
                    localStorage.setItem('customerAdminPlazza', JSON.stringify(user));
                    this.currentAdminSubject.next(user);
                }
                return user;
            }else{
                return user;
            }
        }));
    }

    registerCustomer(data){
        let url_api = this.URL + 'registrar_clientes/registrar_clientes.php'
        return this.http.post<any>(url_api, data)
    }

    registerGuru(data){
        let url_api = this.URL + 'registrar_guru/registrar_guru.php'
        return this.http.post<any>(url_api, data)
    }

    validateCode(data){
        let url_api = this.URL + 'validar_codigo_activacion/validar_codigo_activacion.php'
        return this.http.post(url_api, data)
    }

    activeCliente(data){
        let url_api = this.URL + 'activar_cliente/activar_cliente.php'
        return this.http.post(url_api, data)
    }

    restorePassword(email){
        let url_api = this.URL + 'olvide_pswd/olvide_pswd.php'
        return this.http.post(url_api, email);
    }
    
    confirmRestorePassword(data){
        let url_api = this.URL + 'confirmar_pswd/confirmar_pswd.php'
        return this.http.post(url_api, data);
    }

    changePassword(data, sesion){
        let auxheaders: HttpHeaders = new HttpHeaders({
            'sesion': sesion
        })

        let url_api = this.URL + 'cambio_pswd/cambio_pswd.php'
        return this.http.post(url_api, data, {headers: auxheaders});
    }

    logout() {
        localStorage.removeItem('customerPlazza');
        localStorage.removeItem('customerAdminPlazza');
        this.currentUserSubject.next(null);
        this.currentAdminSubject.next(null);
        this.router.navigate(['/ingresar'])
    }
}