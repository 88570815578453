import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-product-open-pay-popup',
  templateUrl: './product-open-pay-popup.component.html',
  styleUrls: ['./product-open-pay-popup.component.scss']
})
export class ProductOpenPayPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ProductOpenPayPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  viewHtmlPay() {
    try {
      let popupWin = window.open(' ', '_blank');
      popupWin.document.write(this.data.html);
      popupWin.document.close();

    } catch (error) {

    }

    this.dialogRef.close("success")
  }

}
