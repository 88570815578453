import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.ssr';
import { AuthService } from '../auth.service';

const URL_API = environment.domain

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(public http: HttpClient, private authService: AuthService) { }

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  })

  getProfile(id){
    let auxheaders: HttpHeaders = new HttpHeaders({
      'sesion': id
    })

    const url_api = URL_API + 'ver_perfil_cliente/ver_perfil_cliente.php'
    return this.http.get(url_api, { headers: auxheaders });
  }

  updateProfile(data,id){
    let auxheaders: HttpHeaders = new HttpHeaders({
      'sesion': id
    })
    const url_api = URL_API + 'edit_perfil_cliente/edit_perfil_cliente.php'
    return this.http.post(url_api, data,{ headers: auxheaders });

  }

  getOrdenes(id){
    let auxheaders: HttpHeaders = new HttpHeaders({
      'sesion': id
    })

    const url_api = URL_API + 'listar_ordenes/listar_ordenes.php'
    return this.http.get(url_api, { headers: auxheaders });
  }

  getDetailOrden(orden){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
      'sesion': currentUser
    })

    const url_api = URL_API + 'orden/orden.php'
    return this.http.post(url_api,  orden, { headers: auxheaders });
  }

  getListAdjuntos(orden){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
    'sesion': currentUser
    })

    const url_api = URL_API + 'listar_adjuntos_orden/listar_adjuntos_orden.php'
    return this.http.post(url_api, orden, { headers: auxheaders });
  }

  getAddAdjuntos(data){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
    'sesion': currentUser
    })

    const url_api = URL_API + 'add_adjunto_orden/add_adjunto_orden.php'
    return this.http.post(url_api, data, { headers: auxheaders });
  }

  getContenidoAdjunto(data){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
    'sesion': currentUser
    })

    const url_api = URL_API + 'get_contenido_adjunto_orden/get_contenido_adjunto_orden.php'
    return this.http.post(url_api, data, { headers: auxheaders });
  }

  getFactura(orden){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
      'sesion': currentUser
    })

    const url_api = URL_API + 'factura_orden_cliente/factura_orden_cliente.php'
    return this.http.post(url_api,  orden, { headers: auxheaders });

  }

  getRecibo(orden){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
      'sesion': currentUser
    })

    const url_api = URL_API + 'recibo_orden/recibo_orden.php'
    return this.http.post(url_api,  orden, { headers: auxheaders });

  }

  getLiquidación(orden){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
      'sesion': currentUser
    })

    const url_api = URL_API + 'liquidacion_orden/liquidacion_orden.php'
    return this.http.post(url_api,  orden, { headers: auxheaders });
  }

  getRetenciones(orden){    
    const url_api = URL_API + 'ger_retenciones_orden/ger_retenciones_orden.php'
    return this.http.post(url_api,  orden);
  }
  getAdjuntoRetencion(data){
    const url_api = URL_API + 'get_retenciones_orden_documentos/get_retenciones_orden_documentos.php'
    return this.http.post(url_api,  data);
  }

  getPuntos(){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
      'sesion': currentUser
    })

    const url_api = URL_API + 'puntos/puntos.php'
    return this.http.post(url_api, null,{ headers: auxheaders });
  }

  getCotizaciones(id){
    let auxheaders: HttpHeaders = new HttpHeaders({
      'sesion': id
    })

    const url_api = URL_API + 'list_cotizacion/list_cotizacion.php'
    return this.http.get(url_api, { headers: auxheaders });
  }

  getDetailCotizacion(data){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
      'sesion': currentUser
    })

    const url_api = URL_API + 'get_detalle_cotizacion/get_detalle_cotizacion.php'
    return this.http.post(url_api,  data, { headers: auxheaders });
  }

  getListAdjuntosCotizacion(orden){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
    'sesion': currentUser
    })

    const url_api = URL_API + 'adjuntos_cotizacion/adjuntos_cotizacion.php'
    return this.http.post(url_api, orden, { headers: auxheaders });
  }

  getAddAdjuntosCotizacion(data){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
    'sesion': currentUser
    })

    const url_api = URL_API + 'add_adjunto_cotizacion/add_adjunto_cotizacion.php'
    return this.http.post(url_api, data, { headers: auxheaders });
  }

  getContenidoAdjuntoCotizacion(data){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
    'sesion': currentUser
    })

    const url_api = URL_API + 'contenido_adjunto_cotizacion/contenido_adjunto_cotizacion.php'
    return this.http.post(url_api, data, { headers: auxheaders });
  }

  cancelarCotizacion(data){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
    'sesion': currentUser
    })

    const url_api = URL_API + 'cancel_cotizacion/cancel_cotizacion.php'
    return this.http.post(url_api, data, { headers: auxheaders });
  }

  solicitarCotizacion(data){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
    'sesion': currentUser
    })

    const url_api = URL_API + 'add_cotizacion/add_cotizacion.php'
    return this.http.post(url_api, data, { headers: auxheaders });
  }

  getRetencion(data){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
    'sesion': currentUser
    })

    const url_api = URL_API + 'get_retenciones/get_retenciones.php'
    return this.http.post(url_api, data, { headers: auxheaders });
  }

  getRetencionXProduct(data){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
    'sesion': currentUser
    })

    const url_api = URL_API + 'get_precio_producto/get_precio_producto.php'
    return this.http.post(url_api, data, { headers: auxheaders });
  }

  comprarCotizacion(data){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
    'sesion': currentUser
    })

    const url_api = URL_API + 'compra_cotizacion/compra_cotizacion.php'
    return this.http.post(url_api, data, { headers: auxheaders });
  }

  compraDirecta(data){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
    'sesion': currentUser
    })

    const url_api = URL_API + 'compra_directa/compra_directa.php'
    return this.http.post(url_api, data, { headers: auxheaders });
  }

  verificarCompraDirecta(data){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
    'sesion': currentUser
    })

    const url_api = URL_API + 'verificar_transaccion/verificar_transaccion.php'
    return this.http.post(url_api, data, { headers: auxheaders });
  }

  getCuentasBanco(){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
    'sesion': currentUser
    })

    const url_api = URL_API + 'get_cuentas_banco/get_cuentas_banco.php'
    return this.http.post(url_api, { headers: auxheaders });
  }
  
  enviarTransferencia(data){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
    'sesion': currentUser
    })

    const url_api = URL_API + 'transferencia_cotizacion/transferencia_cotizacion.php'
    return this.http.post(url_api, data, { headers: auxheaders });
  }

  verificarPromo(data){
    let currentUser = this.authService.currentUserValue.id_sesion
    let auxheaders: HttpHeaders = new HttpHeaders({
    'sesion': currentUser
    })

    const url_api = URL_API + 'validar_promo/validar_promo.php'
    return this.http.post(url_api, data, { headers: auxheaders });
  }

  /* maybe the true format to send to ccs
    let oReq = new XMLHttpRequest();
    const url_api = this.dom.getDomain() + 'api/main/provider_metal/xls/';

    oReq.open('POST', url_api, true);
    oReq.setRequestHeader("content-type", "application/json");
    oReq.setRequestHeader("Authorization", "Token " + userLocalToken);
  */
}