<div class="modal-header">
    <h2 mat-dialog-title>Adjuntar Comprobante de Retención</h2>
    <button mat-icon-button class="modal-close-btn" (click)="this.dialogRef.close()">
      <mat-icon aria-label="label">close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div [formGroup]="form">
        <!--<span style="font-size: 15px;">Comprobante de retención del {{retention_name}}</span>-->
        <br>
        <mat-form-field class="example-full-width" *ngFor="let item of retentions_by_guru">
            <mat-label >Comprobante de retención del {{item.retention_name}}</mat-label>
            <ngx-mat-file-input formControlName="{{item.retention_file}}" placeholder="{{ 'PLAZZA.FILE' | translate}}" [accept]="'.pdf,.doc,.docx,.png,.jpg,.jpeg'" valuePlaceholder="{{'PLAZZA.FILE_SELECT' | translate}}"  (change)="onChangeFile($event.target.files,'file', item.retention_id)">
            </ngx-mat-file-input>
            <mat-icon matSuffix style="cursor: pointer;" matTooltip="{{'PLAZZA.FILE_SELECT' | translate}}">cloud_upload</mat-icon>
            <mat-error *ngIf="form.controls.file_125.hasError('required')">
                {{'TRANSLATE.REQUIRED_FIELD' | translate}}
            </mat-error>
          </mat-form-field>
    </div>
    
</mat-dialog-content>
  
<mat-dialog-actions align="right">
    <button mat-raised-button (click)="no(data)">Contratar sin hacer retención</button>
    <button mat-raised-button (click)="yes(data)" color="primary" [disabled]="(form.value.file_125 == '' && form.value.file_25 == '' && form.value.file_1 == '' && form.value.file_isv == '')">Contratar y adjuntar retención</button>
</mat-dialog-actions>
