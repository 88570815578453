import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlazzaService } from 'src/app/services/plazza.service';


@Component({
  selector: 'app-show-super-categories',
  templateUrl: './show-super-categories.component.html',
  styleUrls: ['./show-super-categories.component.scss']
})
export class ShowSuperCategoriesComponent implements OnInit {

  @Input('dispatchs') dispatchs: Array<any> = [];

  super_categories:any = [
    { img: "junior_gurus.jpg", name: "", description: "Descripción Junior Gurús", rating: 0},
    { img: "central_empleos.jpg", name: "", description: "Descripción Central de Empleos", rating: 5 },
    { img: "super_gurus.jpg", name: "", description: "Descripción Super Gurús", rating: 0},
  ]

  rating = 0;
  starCount = 5;
  ratingArr: boolean[] = []; // true = solid star; false = empty star

  snackBarDuration = 1000;
  response = [
    'You broke my heart!',
    'Really?',
    'We will do better next time.',
    'Glad you like it!',
    'Thank you so much!'
  ]
 

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    public plazzaService: PlazzaService
  ) { 
    // default to no rating, i.e. all empty stars
    this.ratingArr = Array(this.starCount).fill(false);
  }

  ngOnInit(): void {

  }

  openPage(){
    this.router.navigate(['/products']);
  }
  openGuru(){
    this.router.navigate(['/']);
  }

  returnStar(i: number) {
    if (this.rating >= i + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

  onClick(i: number) {
    this.rating = i + 1;
    this.snackBar.open(this.response[i], '', {
      duration: this.snackBarDuration,
      panelClass: ['snack-bar']
    });

  }

}
