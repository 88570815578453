<div class="modal-header">
    <h2 mat-dialog-title>Solicitar Cotización</h2>
    <button mat-icon-button class="modal-close-btn" (click)="this.dialogRef.close()">
      <mat-icon aria-label="label">close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div [formGroup]="form">
        <br>
        
        <mat-form-field class="example-full-width">
            <mat-label>Comentario:</mat-label>
            <textarea matInput placeholder="" formControlName="comment"></textarea>
            <mat-error *ngIf="form.controls.comment.hasError('required')">
                {{'TRANSLATE.REQUIRED_FIELD' | translate}}
            </mat-error>
        </mat-form-field>

        <span style="font-size: 15px;">Adjunto (Opcional):</span>
        <mat-form-field class="example-full-width">
            <ngx-mat-file-input formControlName="file" placeholder="{{ 'PLAZZA.FILE' | translate}}" [accept]="'.pdf'" valuePlaceholder="{{'PLAZZA.FILE_SELECT' | translate}}" (change)="onChangeFile($event.target.files,'file')">
            </ngx-mat-file-input>
            <mat-icon matSuffix style="cursor: pointer;" matTooltip="{{'PLAZZA.FILE_SELECT' | translate}}">cloud_upload</mat-icon>
            <mat-error *ngIf="form.controls.file.hasError('required')">
                {{'TRANSLATE.REQUIRED_FIELD' | translate}}
            </mat-error>
          </mat-form-field>
    </div>
    
</mat-dialog-content>
  
<mat-dialog-actions align="right">
    <button mat-raised-button (click)="no(data)">Cancelar</button>
    <button mat-raised-button (click)="yes(data)" color="primary">Cotizar</button>
</mat-dialog-actions>
