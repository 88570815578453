<div class="content-pay">
    <mat-card>
        <div class="alert alert-success price-subscription d-flex justify-content-between">
            <img style="height: 35px;  object-fit: contain;" src="assets/images/Guru_logo_positivo.png" alt="Gurus">
            <mat-chip-list>
                <mat-chip color="accent" selected>
                    Lps. {{data?.precio | number: '1.2-2'}}
                </mat-chip>
            </mat-chip-list>
          </div>
    </mat-card>
  
    <mat-card class="card" style="margin-top: 10px;">
      <div>
        <p>Deseamos informarte que tus datos personales y transacción de pago estarán siendo
          procesados a través del método seguro.
        </p>
      </div>
      <div style="margin-top: 10px;">
        <button color="primary" mat-raised-button style="margin-bottom: 10px; width: 100%;"
          (click)="viewHtmlPay()">Continuar</button>
        <button mat-button style="width: 100%;" (click)="close()">Cancelar</button>
      </div>
    </mat-card>
    <mat-card class="card" style="margin-top: 10px;">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row">
                <img style="height: 35px;" src="assets/images/others/check-visasecure.png" alt="Visa Secure">
                <img style="height: 35px;" src="assets/images/others/fac.jpg" alt="fac">  
          </div>
        </div>
    </mat-card>
</div>
