import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProfileService } from 'src/app/services/cliente/profile.service';
//import { SuscriptionService } from '../../../../../../services/admin/access/suscription.service';

@Component({
  selector: 'app-product-load-pay',
  templateUrl: './product-load-pay.component.html',
  styleUrls: ['./product-load-pay.component.scss']
})
export class ProductLoadPayComponent implements OnInit {

  previewData: any
  load: boolean = false


  state = 'LOAD' // 'ERROR' 'SUCCESS'
  interval;
  orderCode;
  messageError = ''
  max = 60000
  init = 0

  constructor(
    private profileService: ProfileService,
    public dialogRef: MatDialogRef<ProductLoadPayComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.orderCode = this.data
  }

  ngOnInit() {
    this.interval = setInterval(() => {
      this.onGetLogOrder()
    }, 10000);
  }

  onGetLogOrder() {
    let data = new FormData()
    data.append('id_orden', this.orderCode)

    this.profileService.verificarCompraDirecta(data).subscribe((res: any) => {

      if(res.respuesta == 0){
        this.state = 'SUCCESS'
        clearInterval(this.interval)
        return
      }
      if(res.respuesta == 1){
        this.state = 'ERROR'
        this.messageError = res.razon
        clearInterval(this.interval)
        return
      }
      if(this.state = 'LOAD'){
        this.init = this.init + 10000
        if (this.init >= this.max) {
          this.state = 'EXPIRED'
          clearInterval(this.interval)
        }else{
          this.state = 'LOAD'
        }
        return
      }
    }, error => {

    })
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  closeSuccess() {
    this.dialogRef.close(this.state)
  }
}
