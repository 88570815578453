
<div class="available-gurus" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" >
    <p class="text-gurus"> Gurús recomendados de la semana.</p>
</div>

<div fxLayout="row wrap" fxLayoutAlign="center center" class="info-bar" fxLayoutGap="35px">
    <div fxLayout="column" fxLayoutAlign="start center" *ngFor="let guru of gurus" >
        <div class="profile-card">
            <div class="profile-img">
                <img [src]="this.plazzaService.getPathImageGuruRecomendado(guru?.img)" onerror="this.src='assets/images/others/not-found.png';" alt="Team Gurús"/>
            </div>
            <div class="profile-content">
                <h2 class="title">{{guru?.description}}
                    <span></span>
                    <p fxLayout="column" fxLayoutAlign="center center" class="linkedin" >
                        <svg (click)="openPage()" class="icon-linked" viewBox="0 0 24 24">
                            <path d="M19 19h-3v-5.3a1.5 1.5 0 0 0-1.5-1.5 1.5 1.5 0 0 0-1.5 1.5V19h-3v-9h3v1.2c.5-.84 1.59-1.4 2.5-1.4a3.5 3.5 0 0 1 3.5 3.5M6.5 8.31c-1 0-1.81-.81-1.81-1.81A1.81 1.81 0 0 1 6.5 4.69c1 0 1.81.81 1.81 1.81A1.81 1.81 0 0 1 6.5 8.31M8 19H5v-9h3m12-8H4c-1.11 0-2 .89-2 2v16a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V4c0-1.11-.9-2-2-2z"/>
                        </svg>
                    </p>
                </h2>
            </div>
        </div>
        <div>
            <p class="guru_name"><b>{{guru?.name}}</b></p>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
            <div>
                <button mat-icon-button *ngFor="let _ of [].constructor(guru?.rating)"
                    class="stars" style="color: rgb(255, 230, 0);" disabled>
                    <mat-icon class="star">
                        star
                    </mat-icon>
                </button>
                <button mat-icon-button *ngFor="let _ of [].constructor(5-guru?.rating)"
                    class="stars" style="color: rgb(255, 230, 0);" disabled>
                    <mat-icon class="star">
                        star_border
                    </mat-icon>
                </button>
            </div>
            <div>
                <a href="/products/guru/detail/{{guru.id}}" class="bn4" style="color: white ;">Ver Más</a>
            </div>
        </div>
        <div>
            <!--<a href="/products" class="bn4" style="color: white ;">Ver Más</a>-->
        </div>
    </div>
</div>

<div class="available-gurus" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" >
    <button class="custom-btn btn-3" (click)="openPage()"><span>Buscar gurús</span></button>
</div>

