<div class="modal-header">
    <h2 mat-dialog-title>Método de pago</h2>
    <button mat-icon-button class="modal-close-btn" (click)="this.dialogRef.close()">
      <mat-icon aria-label="label">close</mat-icon>
    </button>
</div>
<mat-dialog-content>
  <!--
  <div fxLayout="row wrap">

    <div fxLayout="column" fxFlex="100">

      <div fxLayout="row" fxLayoutAlign="center start" class="p-1">
        <h2 fxFlex="35" style="margin-top: 7px;">
          <span class="text-muted mr-1 mb-1" style="font-size: 18px;">Total a Pagar: </span>

          <div *ngIf="retention.retentions == null; else wRetention">
            <div *ngIf="service?.descuento != null; else woOff">
              <p class="old-price text-muted">
                <span style="font-size: 18px;">{{service?.precio | currency: 'L. ':"code"}}</span>
              </p>
              <span style="font-size: 23px;">{{service?.precio_descuento | currency: 'L. ':"code"}}</span>
            </div>
            <ng-template #woOff style="font-size: 23px;">{{service?.precio | currency: 'L. ':"code"}}</ng-template>
          </div>


          <ng-template #wRetention>
            <p class="old-price text-muted">
              <span style="font-size: 18px;">{{service?.precio | currency: 'L. ':"code"}}</span>
            </p>
            <span style="font-size: 23px;">{{retention_new_data?.precio_descuento | currency: 'L. ':"code"}}</span>
          </ng-template>
          
          
        </h2>


        <mat-tab-group fxFlex="65"class="w-100" dynamicHeight mat-align-tabs="center"
        (selectedIndexChange)="onChangeTabFid($event)">
          <mat-tab label="Usar Cupón">
            <div style="padding: 10px;">

              <div fxLayout="row wrap" fxLayoutAlign="center center">
                <span fxFlex>
                  <mat-form-field class="mt-1" fxFlex appearance="outline">
                    <mat-label>Código de Cupón</mat-label>
                    <input matInput placeholder="" [(ngModel)]="_couponText"
                      [ngModelOptions]="{standalone: true}">
                    <mat-hint>Por favor ingrese el código de su cupón</mat-hint>
                  </mat-form-field>
                </span>
                <span style="width: 10px;">

                </span>

                <span>
                  <button class="w-100" color="primary" mat-button
                    (click)="_coupon == null ? onVerifiedCoupon() :onRemoveCoupon() ">

                    <mat-icon class="mat-18">verified</mat-icon>
                    <span *ngIf="_coupon != null">
                      Remover
                    </span>
                    <span *ngIf="_coupon == null">
                      Aplicar
                    </span>

                  </button>
                </span>


              </div>
              <div style="text-align: center; padding: 15px 0px 15px 0px;">

                <p *ngIf="_coupon != null && _coupon.valid == false" style="color: red;">Cupón no válido</p>
                <p *ngIf="_coupon != null && _coupon.valid == true" style="color: green;">Cupón válido, con un
                  descuento de
                  <span *ngIf="_coupon.type == '0'"> {{_coupon.value | currencyCustom}} </span> <span
                    *ngIf="_coupon.type == '1'"> {{_coupon.value}}% </span> sobre tus productos.
                </p>

              </div>
            </div>
          </mat-tab>
          <mat-tab label="Usar Puntos">
            <div style="padding: 10px;">

              <div fxLayout="row wrap" fxLayoutAlign="center center">

                <span fxFlex>
                  <div fxLayout="column" fxFlex="100">
                    <mat-form-field class="mt-1" fxFlex appearance="outline">
                      <mat-label>Ajusta tus puntos</mat-label>
                      <input [disabled]="_point !=null " matInput placeholder="" type="number" step="1"
                        [(ngModel)]="totalPoints" [max]="limitPoints" [ngModelOptions]="{standalone: true} "
                        >
                      <mat-hint>Tienes {{currentPoints}} puntos. (Máximo: {{limitPoints}})</mat-hint>
                    </mat-form-field>
                  </div>

                </span>
                <span style="width: 10px;">

                </span>

                <span>
                  <button class="w-100" color="primary" mat-button
                    (click)="_point == null ?onApliyPoints(): onRemovePoints()">

                    <mat-icon class="mat-18">verified</mat-icon>
                    <span *ngIf="_point != null">
                      Remover
                    </span>
                    <span *ngIf="_point == null">
                      Aplicar
                    </span>

                  </button>
                </span>



              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
        
      </div>

      <div fxLayout="row wrap">
        <div fxFlex="100" class="p-1">
          <div class="divider mb-2"></div>
        </div>
      </div>
    </div>
  
    
  </div>
  <div class="cont-card">
    <img height="28" src="assets/images/card_acept/credimatic-card.png" alt="Credomatic">
    <img height="40" src="assets/images/card_acept/amex-card.png" alt="Amex" style="margin-left: 5px !important;">
    <img height="40" src="assets/images/card_acept/mastercard-card.png" alt="MasterCard">
    <img height="40" src="assets/images/card_acept/visa-card.png" alt="Visa">


  </div>
  <div [formGroup]="creditForm" fxLayout="row wrap">
      <div fxFlex="100" fxFlex.gt-sm="100" class="px-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Nombre en la Tarjeta</mat-label>
            <input matInput type="text" name="BillToFirstName" card-name placeholder="Nombre(s)"
              formControlName="BillToFirstName" autocomplete="ac-BillToFirstName">
            <mat-error *ngIf="creditForm.get('BillToFirstName').hasError('required')">
              Campo obligatorio.
            </mat-error>

          </mat-form-field>
      </div>
      <div fxFlex="100" fxFlex.gt-sm="50" class="px-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Número de Tarjeta de Crédito</mat-label>

            <img matPrefix width="35" style="margin-right: 7px; position: relative; margin-bottom: 15px;" [src]="'assets/images/cards/'+this.getTypeCreditCard(this.creditForm.value.CardNumber)+'.png'" alt="card">
            <input matInput type="text" name="CardNumber" placeholder="" formControlName="CardNumber"
              autocomplete="ac-CardNumber" [mask]="this.mask">
            <mat-error *ngIf="creditForm.get('CardNumber').hasError('required')">
              Campo obligatorio.
            </mat-error>
            <mat-error *ngIf="creditForm.get('CardNumber').hasError('minlength')">
              Mínimo 15 caracteres
            </mat-error>
            <mat-error *ngIf="creditForm.get('CardNumber').hasError('maxlength')">
              Máximo 16 caracteres
            </mat-error>
          </mat-form-field>
      </div>
      <div fxFlex="100" fxFlex.gt-sm="25" class="px-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Fecha de Expiración</mat-label>

            <input matInput type="text" name="CardExpiryDate" placeholder="mm/yy"
              formControlName="CardExpiryDate" autocomplete="ac-CardExpiryDate" mask="99/99">
            <mat-error>
              Fecha no válida
            </mat-error>

          </mat-form-field>

      </div>
      <div fxFlex="100" fxFlex.gt-sm="25" class="px-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>CVC/CVV</mat-label>

            <input matInput type="text" name="CardCVV2" placeholder="0000" formControlName="CardCVV2"
              autocomplete="ac-CardCVV2" mask="9999">
            <mat-error *ngIf="creditForm.get('CardCVV2').hasError('required')">
              Campo obligatorio.
            </mat-error>
            <mat-error *ngIf="creditForm.get('CardCVV2').hasError('minlength')">
              Mínimo 3 caracteres
            </mat-error>
            <mat-error *ngIf="creditForm.get('CardCVV2').hasError('maxlength')">
              Máximo 4 caracteres
            </mat-error>
          </mat-form-field>

      </div>
  </div>
  -->

  <!--<div class="container d-lg-flex">
    <div class="box-1 bg-light user">
        <--<div class="d-flex align-items-center mb-3"> <img src="https://images.pexels.com/photos/4925916/pexels-photo-4925916.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" class="pic rounded-circle" alt="">
            <p class="ps-2 name">Oliur</p>
        </div>--
        <div class="box-inner-1 pb-3 mb-3 ">
            <div class="d-flex justify-content-between mb-3 userdetails">
                <p class="fw-bold">{{service?.nombre}}</p>
                <p class="fw-bold">{{service?.precio | currency: 'L. ':"code"}}</p>
            </div>
            <div id="my" class="carousel slide carousel-fade img-details" data-bs-ride="carousel" data-bs-interval="2000">
                <--<div class="carousel-indicators"> <button type="button" data-bs-target="#my" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button> <button type="button" data-bs-target="#my" data-bs-slide-to="1" aria-label="Slide 2"></button> <button type="button" data-bs-target="#my" data-bs-slide-to="2" aria-label="Slide 3"></button> </div>--
                <div class="carousel-inner">
                    <div class="carousel-item active"> <img src="{{domain}}img_productos/{{service?.id}}.png" onerror="this.src='assets/images/others/not-found.png';" alt="" class="d-block w-100"> </div>
                    <--<div class="carousel-item"> <img src="https://images.pexels.com/photos/270694/pexels-photo-270694.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" class="d-block w-100"> </div>
                    <div class="carousel-item"> <img src="https://images.pexels.com/photos/7974/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&w=500" class="d-block w-100"> </div>--
                </div> 
                  <--<button class="carousel-control-prev" type="button" data-bs-target="#my" data-bs-slide="prev">
                    <div class="icon"> <span class="fas fa-arrow-left"></span> </div> <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#my" data-bs-slide="next">
                    <div class="icon"> <span class="fas fa-arrow-right"></span> </div> <span class="visually-hidden">Next</span>
                </button>--
            </div>
            <p class="dis mb-3 updates">{{service?.subcategoria}}</p>
            <p class="dis my-3 info">{{service?.descripcion}} </p>
            <--<p class="dis mb-3 updates">Free updates forever</p>
            <div class="dis">
                <p class="black"><span class="fas fa-arrow-right mb-3 pe-2"></span>Black</p>
                <p class="white"><span class="fas fa-arrow-right mb-3 pe-2"></span>White</p>
                <p class="pastel"><span class="fas fa-arrow-right mb-3 pe-2"></span>Pastel</p>
            </div>
            <div>
                <p class="dis footer my-3">Here is a quick guide on how to apply them</p>
            </div>--
        </div>
    </div>
    <div class="box-2">
        <div class="box-inner-2">
            <div>
                <p class="fw-bold">Detalles del pago</p>
                <p class="dis mb-3">Complete su compra proporcionando sus datos de pago.</p>

            </div>
            <p class="dis mb-3">Tarjetas validas</p>
            <div class="cont-card">
              <img height="28" src="assets/images/card_acept/credimatic-card.png" alt="Credomatic">
              <img height="40" src="assets/images/card_acept/amex-card.png" alt="Amex" style="margin-left: 5px !important;">
              <img height="40" src="assets/images/card_acept/mastercard-card.png" alt="MasterCard">
              <img height="40" src="assets/images/card_acept/visa-card.png" alt="Visa">
            </div>
            <div [formGroup]="creditForm">
                <div class="mb-3">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Nombre en la Tarjeta</mat-label>
                    <input matInput type="text" name="BillToFirstName" card-name placeholder="Nombre(s)"
                      formControlName="BillToFirstName" autocomplete="ac-BillToFirstName">
                    <mat-error *ngIf="creditForm.get('BillToFirstName').hasError('required')">
                      Campo obligatorio.
                    </mat-error>
        
                  </mat-form-field>
                  <--<p class="dis fw-bold mb-2">Email address</p> <input class="form-control" type="email" value="luke@skywalker.com">--

                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Número de Tarjeta de Crédito</mat-label>
        
                    <img matPrefix width="35" style="margin-right: 7px; position: relative; margin-bottom: 15px;" [src]="'assets/images/cards/'+this.getTypeCreditCard(this.creditForm.value.CardNumber)+'.png'" alt="card">
                    <input matInput type="text" name="CardNumber" placeholder="" formControlName="CardNumber"
                      autocomplete="ac-CardNumber" [mask]="this.mask">
                    <mat-error *ngIf="creditForm.get('CardNumber').hasError('required')">
                      Campo obligatorio.
                    </mat-error>
                    <mat-error *ngIf="creditForm.get('CardNumber').hasError('minlength')">
                      Mínimo 15 caracteres
                    </mat-error>
                    <mat-error *ngIf="creditForm.get('CardNumber').hasError('maxlength')">
                      Máximo 16 caracteres
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="w-50">
                    <mat-label>Fecha de Expiración</mat-label>
        
                    <input matInput type="text" name="CardExpiryDate" placeholder="mm/yy"
                      formControlName="CardExpiryDate" autocomplete="ac-CardExpiryDate" mask="99/99">
                    <mat-error>
                      Fecha no válida
                    </mat-error>
        
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="w-50">
                    <mat-label>CVC/CVV</mat-label>
        
                    <input matInput type="text" name="CardCVV2" placeholder="0000" formControlName="CardCVV2"
                      autocomplete="ac-CardCVV2" mask="9999">
                    <mat-error *ngIf="creditForm.get('CardCVV2').hasError('required')">
                      Campo obligatorio.
                    </mat-error>
                    <mat-error *ngIf="creditForm.get('CardCVV2').hasError('minlength')">
                      Mínimo 3 caracteres
                    </mat-error>
                    <mat-error *ngIf="creditForm.get('CardCVV2').hasError('maxlength')">
                      Máximo 4 caracteres
                    </mat-error>
                  </mat-form-field>
                </div>

                <div style="height: auto;">
                  <div *ngIf="service?.tipo_promocion == 2 || retention_new_data?.tipo_promocion == 2" class="mt-2">
                    <mat-form-field appearance="outline" class="w-50">
                      <mat-label>Puntos</mat-label>
                      <mat-hint>Tus puntos son: {{puntosUser}}</mat-hint>
                      <input matInput type="text" name="Points" placeholder="Puntos"
                        formControlName="Points">
                    </mat-form-field>
                    <div class="btn btn-primary w-50" (click)="verificarPromo('Puntos')">Validar Puntos</div>
                  </div>    
                </div>
               
                <div *ngIf="service?.necesita_cupon == 1 || retention_new_data?.necesita_cupon == 1" class="mt-2">
                  <mat-form-field appearance="outline" class="w-50">
                    <mat-label>Cupón</mat-label>
                    <input matInput type="text" name="Coupon" placeholder="Código del cupón"
                      formControlName="Coupon">        
                  </mat-form-field>
                  <div class="btn btn-primary w-50" (click)="verificarPromo('Cupon')">Validar Cupón</div>
                </div>
  
                <div *ngIf="service?.necesita_bin == 1 || retention_new_data?.necesita_bin == 1" class="mt-2">
                  <mat-form-field appearance="outline" class="w-50">
                    <mat-label>Tarjeta de Promoción</mat-label>
                    <input matInput type="text" name="Coupon" placeholder="Código del cupón"
                      formControlName="Bin">        
                  </mat-form-field>
                  <div class="btn btn-primary w-50" (click)="verificarPromo('Bin')">Validar Tarjeta</div>
                </div>
  
                <div>
                  <section appearance="outline" class="w-100 mb-3">
                    <mat-checkbox formControlName="Invoice"> Necesita Factura</mat-checkbox>
                  </section>
                </div>
               
                <div>
                  <div class="address">
                        <--<p class="dis fw-bold mb-3">Billing address</p> <select class="form-select" aria-label="Default select example">
                            <option selected hidden>United States</option>
                            <option value="1">India</option>
                            <option value="2">Australia</option>
                            <option value="3">Canada</option>
                        </select>
                        <div class="d-flex"> <input class="form-control zip" type="text" placeholder="ZIP"> <input class="form-control state" type="text" placeholder="State"> </div>
                        <div class=" my-3">
                            <p class="dis fw-bold mb-2">VAT Number</p>
                            <div class="inputWithcheck"> <input class="form-control" type="text" value="GB012345B9"> <span class="fas fa-check"></span> </div>
                        </div>
                        <div class="my-3">
                            <p class="dis fw-bold mb-2">Discount Code</p> <input class="form-control text-uppercase" type="text" value="BLACKFRIDAY" id="discount">
                        </div>--
                        <div class="d-flex flex-column dis">
                            <div class="d-flex align-items-center justify-content-between mb-2">
                              <p>Puntos a acumular</p>
                              <p>{{puntos_acumular}}</p>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mb-2">
                                <p>Subtotal</p>
                                <p>{{precio | currency: 'L. ':"code"}}</p>
                            </div>
                            <div  *ngIf="service?.tipo_promocion == 1 || retention_new_data?.tipo_promocion == 1">
                              <div class="d-flex align-items-center justify-content-between mb-2">
                                <p>Descuento</p>
                                <p>{{precio_descuento | currency: 'L. ':"code"}}</p>
                              </div>
                              <div *ngIf="cupon != null" class="d-flex align-items-center justify-content-between mb-2">
                                <p class="pe-2" >Cupón: <span class="d-inline-flex align-items-center justify-content-between bg-light px-2 couponCode"> <span class="pe-2">{{cupon}}</span> <span class="fas fa-times close" (click)="removeCoupon()"></span> </span> </p>
                                <p>{{precio_cupon | currency: 'L. ':"code"}}</p>
                              </div>
                              
                              <div class="d-flex align-items-center justify-content-between mb-2">
                                <p class="fw-bold">Total</p>
                                <p class="fw-bold">{{precio_total | currency: 'L. ':"code"}}</p>
                              </div>
                            </div>
                            <button class="btn btn-primary mt-2" (click)="yes(data)" [disabled]="!creditForm.valid">Pagar</button>
                        </div>
                  </div>
                </div>
                
              </div>
        </div>
    </div>
  </div>-->

  <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutALign.gt-sm="center center" class="container-pay">
    <div class="box-1 bg-light user" ngClass.lt-sm="box-1-small" fxFlex.gt-sm="35" fxLayoutAlign.gt-sm="center start">
      <div class="box-inner-1 pb-3 mb-3">
          <div class="d-flex justify-content-between mb-3 userdetails">
              <p class="fw-bold">{{service?.nombre}}</p>
              <p class="fw-bold">{{service?.precio | currency: 'L. ':"code"}}</p>
          </div>
          <div id="my" class="carousel slide carousel-fade img-details" data-bs-ride="carousel" data-bs-interval="2000">
              <div class="carousel-inner">
                  <div class="carousel-item active"> <img src="{{domain}}img_productos/{{service?.id}}.png" onerror="this.src='assets/images/others/not-found.png';" alt="" class="d-block w-100"> </div>
                  <div class="carousel-item"> <img src="https://images.pexels.com/photos/7974/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&w=500" class="d-block w-100"> </div>--
              </div>
          </div>
          <p class="dis mb-3 updates">{{service?.subcategoria}}</p>
          <p class="dis my-3 info">{{service?.descripcion}} </p>
      </div>
    </div>
    <div class="box-2" fxFlex.gt-sm="65" ngClass.lt-sm="box-2-small" fxLayoutALign.gt-sm="center center">
      <div class="box-inner-2">
        <div>
          <p class="fw-bold">Detalles del pago</p>
          <p class="dis mb-3">Complete su compra proporcionando sus datos de pago.</p>
        </div>
        <div [formGroup]="creditForm">
          <mat-radio-group formControlName="pay" style="width: 100%;">
            <mat-accordion [multi]="false">  
              <mat-expansion-panel style="margin: 0px!important;" class="mat-elevation-z0" [expanded]="creditForm.value.pay == '0'"
              (opened)="creditForm.get('pay').setValue('0') ;">
                <mat-expansion-panel-header>
                  <mat-panel-title  style="padding: 10px 5px;">
                    <mat-radio-button color="primary" [value]="'0'">
                      <div style="font-size: 14px;"> TARJETA DE CREDITO</div>
                    </mat-radio-button>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div fxLayout="row" fxLayoutAlign="center center">
                  <a href="https://www.evertecinc.com/pasarela-de-pagos-e-commerce/" target="_blank">
                    <img style="padding-top: 10px;" width="150" src="https://static.placetopay.com/placetopay-logo.svg"
                    onerror="this.src='assets/images/others/not-found.png';" alt="Place to Pay">
                  </a>
                  <img width="150" [src]="this.plazzaService.getPathImageAlianza('ba.webp')" onerror="this.src='assets/images/others/not-found.png';" alt="Banco Atlántida">
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel style="margin: 0px!important;" class="mat-elevation-z0"
                (opened)="creditForm.get('pay').setValue('1');" [expanded]="creditForm.value.pay =='1'"><!--*ngIf="this.grandTotal < 1500"-->
                  <mat-expansion-panel-header>
                    <mat-panel-title style="padding: 10px 5px;">
                      <mat-radio-button color="primary" [value]="'1'">
                        <div style=" font-size: 14px;">DILO</div>
                      </mat-radio-button>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
      
                  <div class="py-1" fxLayout="row wrap">
                    <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxLayoutAlign="center center" class="pad-1">
                      <img width="150" src="assets/images/payment/diloT.png" onerror="this.src='assets/images/others/not-found.png';" alt="Billetera Digital DILO">
                    </div>
  
                    <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" class="pad-1">
                      <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="35" class="pad-1">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>Ingrese número de celular</mat-label>
                          <input matInput type="text" [(ngModel)]="infoPhone"
                            [ngModelOptions]="{standalone: true}" placeholder="Número de celular">
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
              </mat-expansion-panel>
            </mat-accordion>
          </mat-radio-group>
  
          <div style="height: auto;">
            <div *ngIf="service?.tipo_promocion == 2 || retention_new_data?.tipo_promocion == 2" class="mt-2">
              <mat-form-field appearance="outline" class="w-50">
                <mat-label>Puntos</mat-label>
                <mat-hint>Tus puntos son: {{puntosUser}}</mat-hint>
                <input matInput type="text" name="Points" placeholder="Puntos"
                  formControlName="Points">
              </mat-form-field>
              <div class="btn btn-primary w-50" (click)="verificarPromo('Puntos')">Validar Puntos</div>
            </div>    
          </div>
         
          <div *ngIf="service?.necesita_cupon == 1 || retention_new_data?.necesita_cupon == 1" class="mt-2">
            <mat-form-field appearance="outline" class="w-50">
              <mat-label>Cupón</mat-label>
              <input matInput type="text" name="Coupon" placeholder="Código del cupón"
                formControlName="Coupon">        
            </mat-form-field>
            <div class="btn btn-primary w-50" (click)="verificarPromo('Cupon')">Validar Cupón</div>
          </div>
  
          <div *ngIf="service?.necesita_bin == 1 || retention_new_data?.necesita_bin == 1" class="mt-2">
            <mat-form-field appearance="outline" class="w-50">
              <mat-label>Tarjeta de Promoción</mat-label>
              <input matInput type="text" name="Coupon" placeholder="Código del cupón"
                formControlName="Bin">        
            </mat-form-field>
            <div class="btn btn-primary w-50" (click)="verificarPromo('Bin')">Validar Tarjeta</div>
          </div>
  
          <div>
            <section appearance="outline" class="w-100 mb-3">
              <mat-checkbox formControlName="Invoice"> Necesita Factura</mat-checkbox>
            </section>
          </div>
         
          <div>
            <div class="address">
                  <div class="d-flex flex-column dis">
                      <div class="d-flex align-items-center justify-content-between mb-2">
                        <p>Puntos a acumular</p>
                        <p>{{puntos_acumular}}</p>
                      </div>
                      <div class="d-flex align-items-center justify-content-between mb-2">
                          <p>Subtotal</p>
                          <p>{{precio | currency: 'L. ':"code"}}</p>
                      </div>
                      <div  *ngIf="service?.tipo_promocion == 1 || retention_new_data?.tipo_promocion == 1">
                        <div class="d-flex align-items-center justify-content-between mb-2">
                          <p>Descuento</p>
                          <p>{{precio_descuento | currency: 'L. ':"code"}}</p>
                        </div>
                        <div *ngIf="cupon != null" class="d-flex align-items-center justify-content-between mb-2">
                          <p class="pe-2" >Cupón: <span class="d-inline-flex align-items-center justify-content-between bg-light px-2 couponCode"> <span class="pe-2">{{cupon}}</span> <span class="fas fa-times close" (click)="removeCoupon()"></span> </span> </p>
                          <p>{{precio_cupon | currency: 'L. ':"code"}}</p>
                        </div>
                        
                        <div class="d-flex align-items-center justify-content-between mb-2">
                          <p class="fw-bold">Total</p>
                          <p class="fw-bold">{{precio_total | currency: 'L. ':"code"}}</p>
                        </div>
                      </div>
                      <button class="btn btn-primary mt-2" (click)="yes(data)" [disabled]="!creditForm.valid">Pagar</button>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</mat-dialog-content>
<!--
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="yes(data)" style="background-color: #74dd98; color: #ffffff;">Pagar</button>
</mat-dialog-actions>
-->