<div fxLayout="row" fxLayout.xs="column" [fxLayoutAlign]="align" class="text-muted">
    <div *ngIf="(type=='all' || type=='wish')"> <!--product?.inventario > 0 && -->
        <bdi>
            <span *ngIf="type!='wish'" class="fw-500">Cantidad:</span> 
            <button mat-icon-button (click)="decrement()"><mat-icon>remove</mat-icon></button>
            <span> {{count}} </span>
            <button mat-icon-button (click)="increment()"><mat-icon>add</mat-icon></button>
        </bdi>
    </div>
    <div *ngIf="type!='wish'" fxLayoutAlign="space-between center" >
        <!--<button mat-icon-button matTooltip="Add to wishlist" (click)="addToWishList(product)"><mat-icon>favorite</mat-icon></button>-->
        <!--<button mat-icon-button matTooltip="Add to compare" (click)="addToCompare(product)"><mat-icon>compare</mat-icon></button>-->
        <div>
            <button *ngIf="type!='all'" mat-icon-button matTooltip="Ver" (click)="openProductDialog(product)"><mat-icon>remove_red_eye</mat-icon></button>
            <button mat-icon-button matTooltip="Agregar a Carrito" *ngIf="product?.inventario > 0" (click)="addToCart(product)"><mat-icon>shopping_cart</mat-icon></button>
        </div>
    </div>
</div> 
<div *ngIf="product?.inventario == 0 && type=='all'" class="bg-warn p-1 mt-2">
    El item está agotado.
</div>