import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { PlazzaService } from '../../../services/plazza.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public lat: number = 40.678178;
  public lng: number = -73.944158;
  public zoom: number = 12;

  plazza: any
  subsText = ''

  obs: Subscription[] = []
  user = null


  constructor(
    private plazzaService: PlazzaService,
    private authService: AuthService,
  ) { 
    this.obs.push(
      this.authService.currentUser.subscribe(res => {
        this.user = res == null ? null : res
      })
    )
   }

  ngOnInit() { 
    this.getSocialMedia()
  }

  subscribe(){ }
  
  openLink(ele) {
    window.open(ele, '_blank');
  }

  getSocialMedia(){
    this.plazzaService.getConfiguration().subscribe((data:any)=>{
      this.plazza = data.redes_sociales ? data.redes_sociales : null;
    })
  }

  onSubsFormSubmit(){
    
  }
}