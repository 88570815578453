<mat-sidenav-container> 
    
    <!--<div fxLayout="row" fxLayoutAlign="space-between center" class="info-header p-1" *ngIf="viewHeader && message">
        <span fxFlex="90" fxLayoutAlign="center">{{this.message}}</span>
        <button fxLayoutAlign="right" mat-icon-button style="color: white;" (click)="closeHead()">
            <mat-icon>close</mat-icon>
        </button>
    </div> --> 
    
    <mat-sidenav [opened]="false" mode="over" #sidenav class="sidenav mat-elevation-z6">
        <button mat-icon-button color="warn" class="close" (click)="sidenav.close()">
           <mat-icon color="warn">close</mat-icon>
        </button>
        <div class="divider"></div>
        <div fxLayout="column" class="py-2"> 
            <a mat-button routerLink="/" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.HOME' | translate }}</a>
            <!--<a mat-button routerLink="/alianzas-estratégicas" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Alianzas Estratégicas</a>
            <a mat-button routerLink="/fundación" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Nuestra Fundación</a>
            <a mat-button routerLink="/nosotros" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Acerca de</a>
            <a mat-button routerLink="/contact" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.CONTACT' | translate }}</a>-->
            <a mat-button routerLink="/nosotros" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Quiénes Somos</a>
            <a mat-button routerLink="/como-funciona" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Cómo Funciona</a>
            <a mat-button routerLink="/alianzas-estratégicas" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Alianzas Estratégicas</a>
            <a mat-button routerLink="/faq" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Preguntas Frecuentes</a>
        </div>

        <!--<app-sidenav-menu [menuItems]="sidenavMenuItems" [menuParentId]="0"></app-sidenav-menu>-->
        <div class="divider"></div>
        <div class="text-center py-1">
            <a href="https://www.gurushonduras.com/" target="_blank">
                <img src="assets/images/guru.png" alt="Gurús" width="80" height="25"/>
            </a>
        </div>
        <div class="text-center py-1">  
            <svg class="social-icon px-1" viewBox="0 0 24 24">
                <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"/>
            </svg>
            <svg class="social-icon px-1" viewBox="0 0 24 24">
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
            </svg>
            <svg class="social-icon px-1" viewBox="0 0 24 24">
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
            </svg>
        </div> 
    </mat-sidenav>

    <app-menu fxShow="false" fxShow.gt-sm></app-menu>

    <mat-toolbar id="header-toolbar" class="toolbar-bg">
        <mat-toolbar-row fxLayoutAlign="space-between center" class="logo-toolbar theme-container">
            <span fxHide="false" fxHide.gt-sm>
                <button mat-button (click)="sidenav.toggle()" class="sidenav-toggle">
                    <mat-icon>menu</mat-icon>
                </button> 
            </span>
            <!--<app-top-menu></app-top-menu>-->
            <div fxFlex class="content-logo">
                <a class="logo" routerLink="/" (click)="closeSubMenus()">
                    <img src="assets/images/gurus_logo.png" class="logo-img" alt="Gurus" />
                </a>
            </div>
            <div *ngIf="showSearch" fxFlex="50" fxShow="false" fxShow.gt-sm >
                <form method="get" class="search-form" fxLayout="row">
                    <!--<button mat-raised-button [matMenuTriggerFor]="categoriesMenu" #categoriesMenuTrigger="matMenuTrigger" type="button" class="mat-elevation-z0 categories text-truncate text-muted">
                        {{superCategory?.nombre}}<mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <mat-menu #categoriesMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="categories-dropdown">
                        <app-supercategory-list [superCategories]="superCategories" (change)="changeSuperCategory($event)"></app-supercategory-list>
                    </mat-menu>-->
                    <input class="categories" type="text" [ngModelOptions]="{standalone: true}" placeholder="Encuentra un Gurú ideal para la actividad que quieras realizar" [(ngModel)]="search" fxFlex>
                     <button mat-mini-fab type="button" *ngIf="search" (click)="clearSearch()" class="clear_text mat-elevation-z0 text-muted" style="padding-right: 20px;">
                        <mat-icon>close</mat-icon>
                    </button>
                    <button  mat-button (click)="onSearch()" type="button" class="search-btn mat-elevation-z0 text-white">
                        <mat-icon>search</mat-icon>
                    </button> 
                </form>
            </div>
            <div fxFlex="35" fxLayout="row" fxLayoutAlign="end center">   <!--fxFlex="35" sin esto se pasa la pantalla-->
                <div fxLayoutAlign="center center" [@.disabled]="true" style="margin-bottom: 5px;">
                    <!--<button mat-button routerLink="/registrar/P" class="top-plazza" ngClass.xs="icon-button">
                        <div fxLayout="column" fxLayoutAlign="center center">
                            <mat-icon class="mat-icon-md">store</mat-icon>
                            <span fxShow="false" fxShow.gt-sm>
                                Vende en Plazza
                            </span>
                        </div>
                    </button>-->
                    <div *ngIf="this.user == null" class="log-btns">
                        
                        <!--<a href="/registrar/P" class="bn3" style="color: white ;" disabled>Soy Gurú</a>-->
                        <div fxLayout="row">
                            <div class="client-div" (click)="openPage('guru')">
                                <a class="bred" ><mat-icon class="client-icon">group</mat-icon></a>
                                <a  class="bn4" style="color: white ;">Soy Gurú</a>
                            </div>
                            <div class="client-div" (click)="openPage('cliente')">
                                <a class="bred" ><mat-icon class="client-icon">person</mat-icon></a>
                                <a  class="bn4" style="color: white ;">Soy Cliente</a>
                            </div>
                        </div>
                        
                        
                    </div>
                    <button mat-button *ngIf="this.user == null" class="top-plazza iniciar-sesion" ngClass.xs="icon-button" routerLink="/ingresar">
                        <div fxLayout="column" fxLayoutAlign="center center" >
                            <mat-icon class="mat-icon-md">person</mat-icon>
                            <span fxShow="false" fxShow.gt-sm>
                                {{ 'Ingresar'}}
                            </span>
                        </div>   
                    </button>
                    <ng-container *ngIf="this.user != null">
                        <button mat-button class="top-plazza" ngClass.xs="icon-button" [matMenuTriggerFor]="accountMenu" #accountMenuTrigger="matMenuTrigger">
                            <div fxLayout="column" fxLayoutAlign="center center" >
                                <mat-icon class="mat-icon-md">person</mat-icon>
                                <span fxShow="false" fxShow.gt-sm>
                                    {{ 'ACCOUNT' | translate }}
                                </span>
                            </div>   
                        </button>
                        <mat-menu #accountMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown account">
                            <span (mouseleave)="accountMenuTrigger.closeMenu()">
                                <div fxLayout="row" fxLayoutAlign="space-around center" class="user-info">                   
                                    <img src="assets/images/others/user.jpg" alt="user-image" width="50">
                                    <p>{{profile_user?.nombre}} <br> <small> Alias: {{profile_user?.alias}}</small></p>
                                </div>
                                <div class="divider"></div>
                                <a mat-menu-item routerLink="/cuenta"> 
                                    <mat-icon class="mat-icon-sm">info</mat-icon>
                                    <span>Mi Cuenta</span> 
                                </a>
                                <a mat-menu-item routerLink="/cuenta/points"> 
                                    <mat-icon class="mat-icon-sm">attach_money</mat-icon>
                                    <span>Mis Puntos</span> 
                                </a>
                                <a mat-menu-item routerLink="/cuenta/orders"> 
                                    <mat-icon class="mat-icon-sm">add_shopping_cart</mat-icon>
                                    <span>Mis Ordenes</span> 
                                </a>
                                <a mat-menu-item routerLink="/cuenta/quotes"> 
                                    <mat-icon class="mat-icon-sm">description</mat-icon>
                                    <span>Mis Cotizaciones</span> 
                                </a>
                                <a mat-menu-item routerLink="/cuenta/message"> 
                                    <mat-icon class="mat-icon-sm">message</mat-icon>
                                    <span>Mis Mensajes</span> 
                                </a>
                                <div class="divider"></div>
                                <a mat-menu-item (click)="logout()"> 
                                    <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
                                    <span>{{ 'LOGOUT' | translate }}</span> 
                                </a>
                            </span>
                        </mat-menu>
                    </ng-container>
                    <!--
                    <button mat-button class="top-plazza" ngClass.xs="icon-button" [matMenuTriggerFor]="cartMenu" #cartMenuTrigger="matMenuTrigger">
                        <div fxLayout="column" fxLayoutAlign="center center">
                            <mat-icon class="mat-icon-md" style="font-size: 25px;">shopping_cart</mat-icon>-->
                            <!--<span *ngIf="plazzaService.Data.totalCartCount > 0" class="cart-items-count">{{plazzaService.Data.totalCartCount}}</span>-->
                            <!--<span fxShow="false" fxShow.gt-sm>
                                Mi Carrito-->
                                <!--<mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>-->
                            <!--</span>
                            <div fxLayout="column" fxShow="false" fxShow.gt-sm class="top-cart">
                                <span *ngIf="plazzaService.Data.totalCartCount > 0">
                                    <bdi>{{plazzaService.Data.totalCartCount}} Producto(s)<span *ngIf="plazzaService.Data.totalCartCount > 1">s</span> - 
                                        $<span>{{plazzaService.Data.totalPrice | number : '1.2-2'}}</span>
                                    </bdi>
                                </span>
                            </div>
                        </div>
                    </button>
                    -->
                    <mat-menu #cartMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="top-menu-dropdown cart-dropdown">
                        <ng-template matMenuContent>
                            <span (mouseleave)="cartMenuTrigger.closeMenu()" class="d-block">
                                <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted">
                                    <b><span>{{plazzaService.Data.totalCartCount}} PRODUCTO<span *ngIf="plazzaService.Data.totalCartCount > 1">S</span></span></b>
                                    <b><a [routerLink]="['/carrito']" class="text-muted">Ver Carrito</a></b>
                                </div>
                                <div class="divider mt-1"></div>
                                <div *ngIf="plazzaService.Data.totalCartCount == 0" class="py-1 text-muted text-center">No tienes items en tu carrito.</div>
                                <mat-list>
                                    <mat-list-item *ngFor="let product of plazzaService.Data.cartList">                                   
                                        <h3 matLine class="text-muted"> {{product.name}} </h3>
                                        <p matLine class="text-muted">
                                            <bdi>{{product.cartCount}} x ${{product.newPrice | number : '1.2-2'}}</bdi>
                                        </p>
                                        <img [src]="this.plazzaService.getPathImageProduct(product.id)" 
                                        onerror="this.src='assets/images/others/not-found.png';" alt="image">
                                        <button mat-icon-button color="warn" class="remove" (click)="remove(product);stopClickPropagate($event)" matTooltip="Remove" matTooltipPosition="before">
                                            <mat-icon class="mat-icon-sm">close</mat-icon>
                                        </button>
                                    </mat-list-item>
                                </mat-list>
                                <div *ngIf="plazzaService.Data.cartList.length > 0" class="cart-dropdown-footer">
                                    <div class="divider mt-1"></div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted mt-1">
                                        <b>TOTAL:</b>
                                        <b class="new-price">${{plazzaService.Data.totalPrice | number : '1.2-2'}}</b>
                                    </div>
                                    <div class="divider mt-1"></div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-1">
                                        <button mat-mini-fab color="warn" (click)="clear();stopClickPropagate($event)" matTooltip="Clear All" matTooltipPosition="after">
                                            <mat-icon>remove_shopping_cart</mat-icon>
                                        </button>
                                        <a [routerLink]="['/checkout']" mat-mini-fab color="primary" matTooltip="Checkout" matTooltipPosition="before">
                                            <mat-icon>check</mat-icon>
                                        </a>
                                    </div>
                                </div>
                            </span>
                        </ng-template>
                    </mat-menu>
                </div> 
            </div> 
        </mat-toolbar-row>

        <mat-toolbar-row *ngIf="showSearch" fxLayoutAlign="center center" class="search-toolbar theme-container" fxHide="false" fxHide.gt-sm [@.disabled]="true">
            <form method="get" class="search-form pb-4" fxLayout="row">
                <!--<button mat-raised-button [matMenuTriggerFor]="categoriesMenu" #categoriesMenuTrigger="matMenuTrigger" type="button" class="mat-elevation-z0 categories text-truncate text-muted">
                        {{superCategory?.nombre}}<mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #categoriesMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="categories-dropdown">
                    <app-supercategory-list [superCategories]="superCategories" (change)="changeSuperCategory($event)"></app-supercategory-list>
                </mat-menu>-->
                <input type="text" [ngModelOptions]="{standalone: true}" placeholder="Encuentra un Gurú ideal para la actividad que quieras realizar"  [(ngModel)]="search" fxFlex>
                <button mat-mini-fab type="button" *ngIf="search" (click)="clearSearch()" class="clear_text mat-elevation-z0 text-muted" style="padding-right: 20px;">
                    <mat-icon>close</mat-icon>
                </button>
                <button  mat-button (click)="onSearch()" type="button" class="search-btn mat-elevation-z0 text-white">
                    <mat-icon>search</mat-icon>
                </button> 
            </form>
        </mat-toolbar-row>
    </mat-toolbar>  
    
    <div class="theme-container main"> 
        <app-breadcrumb></app-breadcrumb>
        <router-outlet></router-outlet>
    </div>
    

    <!--<app-options></app-options>--> <!-- change color-->
    <app-footer></app-footer>
    <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition" (click)="scrollToTop()">
        <mat-icon>arrow_upward</mat-icon>
    </div>


 </mat-sidenav-container>