import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCustomComponent } from 'src/app/dialog-custom/dialog-custom.component';

import { ProductPaymentComponent } from '../product-payment/product-payment.component';


@Component({
  selector: 'app-product-retention-receipt',
  templateUrl: './product-retention-receipt.component.html',
  styleUrls: ['./product-retention-receipt.component.scss']
})
export class ProductRetentionReceiptComponent implements OnInit {

  public form: FormGroup

  service_id = ''

  file: any = {}
  files: any = []

  retention_name: string = '';
  retention_id:any;
  retentions: any = [];

  retentions_by_guru: any = [];

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ProductRetentionReceiptComponent>, 
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
    
    ) {
      this.form = this.fb.group({
        file_125: ['', Validators.compose([])],
        file_25: ['', Validators.compose([])],
        file_1: ['', Validators.compose([])],
        file_isv: ['', Validators.compose([])],
      })
    }


  ngOnInit() {
    console.log(this.data.data)
    this.initRetention()
  }

  initRetention(){


    let example


    this.retentions = Object.values(this.data.data)

    //console.log('retencion en arreglo', this.retentions)
    /*
      id:1->12.5%
      id:2->25%
      id:3->1%
      id:4->ISV
    */
    if(this.retentions[0] == 1){
      this.retentions_by_guru.push({retention_name: '12.5%', retention_id: 1, retention_file: 'file_125', file: '' })
      /*this.retention_name = '12.5%'
      this.retention_id = 1*/
    } else if(this.retentions[1] == 1){
      this.retentions_by_guru.push({retention_name: '25%', retention_id: 2, retention_file: 'file_25', file: '' })
      /*this.retention_name = '25%'
      this.retention_id = 2*/
    } else if(this.retentions[2] == 1){
      this.retentions_by_guru.push({retention_name: '1%', retention_id: 3, retention_file: 'file_1', file: '' })
      /*this.retention_name = '1%'
      this.retention_id = 3*/
    } else if(this.retentions[3] == 1){
      this.retentions_by_guru.push({retention_name: 'ISV', retention_id: 4, retention_file: 'file_isv', file: '' })
      /*this.retention_name = "ISV"
      this.retention_id = 4*/
    }

    //Borrar
    //this.retentions_by_guru.push({retention_name: '1%', retention_id: 3, retention_file: 'file_1', file: '' })
  }

  yes(element) {
    this.dialogRef.close({
      result: 'Success',
      obj:{
        retentions: this.retentions_by_guru
      }
    });
  }
  no(element) {
    this.dialogRef.close({
      result: 'Success',
      obj:{
        retentions: null
      }
    });
  }

  // Obtiene base64 y nombre de un archivo
  onChangeFile(files: any, type: string, retention_id: any) {
    let file = files[0]
    if (file) {
      var blob = file.slice(0, file.size, file.type);
      let newName = file.name
      
      let newFile = new File([blob], `${newName}`, {type: file.type});

      const reader = new FileReader();
      reader.readAsDataURL(newFile);

      reader.onload = () => {
        let aux = {
          base64: reader.result,
          fileName: `${newName}`,
        }
        this.agregarFile(aux, retention_id)
      };
      
    } else {
      //delete this.file[type]
    }
  }

  agregarFile(file: any, retention_id: any){
    this.retentions_by_guru.forEach(e => {
      if(e.retention_id == retention_id){
        e.file = file
      }
    });
    //console.log(this.retentions_by_guru)
  }


}
