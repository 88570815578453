import { Component, OnInit, Input} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ProfileService } from 'src/app/services/cliente/profile.service';
import { PlazzaService } from '../../../services/plazza.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  
  plazza: any
  obs: Subscription[] = []
  user = null
  profile_user = null
  
  constructor(
    private plazzaService: PlazzaService,
    private authService: AuthService,
    private profileService: ProfileService,
  ) { 
    this.obs.push(
      this.authService.currentUser.subscribe(res => {
        this.user = res == null ? null : res
        if(res && res.id_sesion){
          this.getNameUser()
        }
      })
    )
  }

  ngOnInit() {
    this.getSocialMedia()
  }

  openMegaMenu(){
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
        if(el.children.length > 0){
          if(el.children[0].classList.contains('mega-menu')){
            el.classList.add('mega-menu-pane');
          }
        }        
    });
  }

  getSocialMedia(){
    this.plazzaService.getConfiguration().subscribe((data:any)=>{
      this.plazza = data.redes_sociales ? data.redes_sociales : null;
    })
  }

  openLink(ele) {
    window.open(ele, '_blank');
  }
  getNameUser(){
    this.profileService.getProfile(this.user.id_sesion).subscribe(data => {
      this.profile_user = data
    }, error => {
      console.log("error",error)
    })
  }
}
