import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/auth.service';


@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private AuthService: AuthService,
    public snackBar: MatSnackBar
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.AuthService.currentAdminValue
    if (currentUser) {
      if(currentUser.id_sesion == -1){
        /*switch (currentUser.respuesta) {
          case -1: //solicitud no llenado
            this.router.navigate(['/admin/register/todo-pago'])
            return true;
            case 0: //solicitud en revisión
              this.router.navigate(['/admin/register/todo-pago'])
              return true;
            case 1: //solicitud aprobada y validada
              return true;
          case 2: //solicitud rechazada
            this.snackBar.open('Cuenta Bloqueada', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });  
            this.router.navigate(['/ingresar'], { queryParams: { returnUrl: state.url } });          
            return false;
          case 3: //solicitud aprobada pero falta validar 
            this.router.navigate(['/admin/register/todo-pago'])
            return true;
          case 4: //verificar credenciales de TODO PAGO
            this.router.navigate(['/admin/register/todo-pago'])
            return true;
          default:
            this.router.navigate(['/ingresar'], { queryParams: { returnUrl: state.url } });          
            return false;
        }*/
        switch (currentUser.respuesta) {
          case 4: //Gurú sin completar perfil
            this.router.navigate(['/auth-verificar'])
            return false;
          case 3: //Gurú rechazado
            this.snackBar.open(currentUser.razon, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });  
            this.router.navigate(['/ingresar'], { queryParams: { returnUrl: state.url } });          
            return false;
          case 5: //Gurú expulsado
            this.snackBar.open(currentUser.razon, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });  
            this.router.navigate(['/ingresar'], { queryParams: { returnUrl: state.url } });          
            return false;
          case 2: //Gurú en proceso de evaluacion
            this.snackBar.open(currentUser.razon, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });  
            this.router.navigate(['/ingresar'], { queryParams: { returnUrl: state.url } });          
            return false;
          default:
            this.router.navigate(['/ingresar'], { queryParams: { returnUrl: state.url } });          
            return false;
        }
        //return false;
      }
      else{
        return true;
      }
      
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/ingresar'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}