




<div fxLayout="column wrap"  fxLayoutAlign="center center"  class="tab-gurus">
    <!--<p class="tab-title">
      {{dispatchs?.hogar}} {{dispatchs?.empresa}} {{dispatchs?.digital}}
    </p>-->

    <!--div class="tabset" fxFlex="80">
      <mat-tab-group [selectedIndex]="selectedTabIndex" (selectedIndexChange)="tabChanged($event)">
        <mat-tab *ngFor="let item of supercategories; let index = index">
          <ng-template mat-tab-label>
            <span (click)="getCategories(item.id)" class="text-label">{{item.nombre}}</span>
          </ng-template>
          <div class="imglist">
            <ul>
              <li *ngFor="let cate of categories">
                <mat-icon color="primary">done</mat-icon>
                <a href="" target="_blank">{{get20Caracteres(cate?.nombre)}}</a>
              </li>
            </ul>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>-->

    <!--<div class="tabset">
        <input type="radio"  id="tab1" aria-controls="1" value="1"  [checked]="dispatchs?.hogar" >
        <label for="tab1" (click)="getTab(1)">Gurús para el Hogar</label>

        <input type="radio"  id="tab2" aria-controls="2" value="2"  [checked]="dispatchs?.empresa">
        <label for="tab2" (click)="getTab(2)">Gurús para las Empresas</label>

        <input type="radio"  id="tab3" aria-controls="3" value="3"  [checked]="dispatchs?.digital">
        <label for="tab3" (click)="getTab(3)">Gurús Digitales</label>

        <div class="tab-panels">
          <section id="1" class="tab-panel">
            <div class="imglist">
              <ul>
                <li *ngFor="let cate of categories | paginate: { itemsPerPage: this.optionPaginate.itemPerPage, currentPage: this.optionPaginate.currentPage, totalItems: this.optionPaginate.totalItems }">
                  <mat-icon color="primary" class="icon">done</mat-icon>
                  <a [routerLink]="['/products',cate.nombre]" [queryParams]="{ category: cate.id}">{{get30Caracteres(cate?.nombre)}}</a>
                </li>
              </ul>
            </div>
          </section>
          <section id="2" class="tab-panel">
            <div class="imglist">
              <ul>
                <li *ngFor="let cate of categories | paginate: { itemsPerPage: this.optionPaginate.itemPerPage, currentPage: this.optionPaginate.currentPage, totalItems: this.optionPaginate.totalItems }">
                  <mat-icon color="primary" class="icon">done</mat-icon>
                  <a [routerLink]="['/products',cate.nombre]" [queryParams]="{ category: cate.id}">{{get30Caracteres(cate?.nombre)}}</a>
                </li>
              </ul>
            </div>
          </section>
          <section id="3" class="tab-panel">
            <div class="imglist">
              <ul>
                <li *ngFor="let cate of categories | paginate: { itemsPerPage: this.optionPaginate.itemPerPage, currentPage: this.optionPaginate.currentPage, totalItems: this.optionPaginate.totalItems }">
                  <mat-icon color="primary" class="icon">done</mat-icon>
                  <a [routerLink]="['/products',cate.nombre]" [queryParams]="{ category: cate.id}">{{get30Caracteres(cate?.nombre)}}</a>
                </li>
              </ul>
            </div>
          </section>
        </div>
    </div>

    <div class="p-0 text-center">           
      <pagination-controls class="product-pagination" autoHide="true" (pageChange)="onPageChanged($event)"
      previousLabel="Atrás" nextLabel="Siguiente"></pagination-controls>
    </div>
    <hr class="separator separator--dots" />
  -->

  <div class="super-categorias" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" >
    <p class="title-supercategorias"> Super categorías</p>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="center center" class="info-bar" fxLayoutGap.gt-xs="50px">
    <div fxLayout="column" fxLayoutAlign="start center" *ngFor="let super_category of super_categories" >
        <div class="profile-card">
            <div class="profile-img">
                <img src="https://plazza.s3.us-east-2.amazonaws.com/folder/gurus/superCategorias/{{super_category?.img}}" alt="Super Categorias"/>
            </div>
            <div class="profile-content">
                <h2 class="title">{{super_category?.description}}
                    <span></span>
                    <p fxLayout="column" fxLayoutAlign="center center" class="linkedin" >
                        <!--<svg (click)="openPage()" class="icon-linked" viewBox="0 0 24 24">
                            <path d="M19 19h-3v-5.3a1.5 1.5 0 0 0-1.5-1.5 1.5 1.5 0 0 0-1.5 1.5V19h-3v-9h3v1.2c.5-.84 1.59-1.4 2.5-1.4a3.5 3.5 0 0 1 3.5 3.5M6.5 8.31c-1 0-1.81-.81-1.81-1.81A1.81 1.81 0 0 1 6.5 4.69c1 0 1.81.81 1.81 1.81A1.81 1.81 0 0 1 6.5 8.31M8 19H5v-9h3m12-8H4c-1.11 0-2 .89-2 2v16a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V4c0-1.11-.9-2-2-2z"/>
                        </svg>-->
                    </p>
                </h2>
            </div>
        </div>
      </div>
  </div>

  <div class="available-gurus" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" >
    <p class="text-gurus"> Hoy hay <b>7,466</b> gurús disponibles y esperando por ti.</p>
    <button class="custom-btn btn-3" (click)="openPage()"><span>Buscar gurús</span></button>
  </div>
  
</div>
