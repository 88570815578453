import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-pending',
  templateUrl: './modal-pending.component.html',
  styleUrls: ['./modal-pending.component.scss']
})
export class ModalPendingComponent implements OnInit {

  loadSave = false

  constructor(
    public dialogRef: MatDialogRef<ModalPendingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onYes(){
    this.loadSave = true
    setTimeout(() => {
      this.dialogRef.close("success");
      this.loadSave = false
    }, 1000);
  }
}
