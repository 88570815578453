import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {
  //@Input('slides') slides: Array<any> = [];
 
  result:any = [
    "Categoría 1",
    "Categoría 2",
    "Categoría 3",
    "Categoría 4",
    "Categoría 5",
    "Categoría 6",
    "Categoría 7",
    "Categoría 8",
    "Categoría 9",
    "Categoría 10",
    "Categoría 11",
    "Categoría 12",
    /*"Categoría 3",
    "Categoría 4",
    "Categoría 5",
    "Categoría 1",
    "Categoría 2",
    "Categoría 3",
    "Categoría 4",
    "Categoría 5",
    "Categoría 1",
    "Categoría 2",
    "Categoría 3",
    "Categoría 4",
    "Categoría 5",
    "Categoría 1",
    "Categoría 2",*/
  ]

  gurus:any = [
    {
      id: 1,
      name: "Laura Gabriela Rivera Gallegos",
      state: true,
      score: 5,
    },
    {
      id: 2,
      name: "Damian Portillo",
      state: false,
      score: 5,
    },
    {
      id: 3,
      name: "Albatros Services",
      state: true,
      score: 5,
    },
    {
      id: 4,
      name: "Angel Blandin",
      state: false,
      score: 5,
    },
    {
      id: 5,
      name: "Dolores Pinel",
      state: true,
      score: 5,
    },
  ]

  services:any = [
    {
      id: 1,
      title: "DISEÑO DE PRESENTACIONES",
      sub_title: "DISEÑO DE PRESENTACIONES",
      price: 350.00,
      description: "Realizamos, talleres, presentaciones audivisuales, elaboracion de carpetas fisicas o digitales. diseño de logos, publicidad en redes sociales.",
    },
    {
      id: 2,
      title: "DISEÑO DE PRESENTACIONES",
      sub_title: "DISEÑO DE PRESENTACIONES, CATALOGOS Y ANUNCIOS PUBLICITARIOS DIGITALES",
      price: 100.00,
      description: "Realizamos, talleres, publicidad en redes sociales.",
    },
    {
      id: 3,
      title: "DISEÑO DE PRESENTACIONES",
      sub_title: "DISEÑO DE PRESENTACIONES, CATALOGOS Y ANUNCIOS PUBLICITARIOS DIGITALES",
      price: 50.00,
      description: "Realizamos, talleres, presentaciones audivisuales, elaboracion de carpetas fisicas o digitales. diseño de logos, publicidad en redes sociales.",
    },
    {
      id: 4,
      title: "DISEÑO DE PRESENTACIONES",
      sub_title: "DISEÑO DE PRESENTACIONES, CATALOGOS Y ANUNCIOS PUBLICITARIOS DIGITALES",
      price: 200.00,
      description: "Realizamos, talleres, presentaciones audivisuales, elaboracion de carpetas fisicas o digitales. diseño de logos, publicidad en redes sociales.",
    },
    {
      id: 5,
      title: "DISEÑO DE PRESENTACIONES",
      sub_title: "DISEÑO DE PRESENTACIONES, CATALOGOS Y ANUNCIOS PUBLICITARIOS DIGITALES CATALOGOS Y ANUNCIOS PUBLICITARIOS DIGITALES",
      price: 300.00,
      description: "Realizamos, talleres, presentaciones audivisuales, elaboracion de carpetas fisicas o digitales. diseño de logos, publicidad en redes sociales. Realizamos, talleres, presentaciones audivisuales, elaboracion de carpetas fisicas o digitales. diseño de logos, publicidad en redes sociales.",
    },
  ]


  public config: SwiperConfigInterface = {};

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };

  advanceSearch = false
  
  constructor() { }

  ngOnInit() { }

  ngAfterViewInit(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,     
      autoplay: {
        delay: 6000,
        disableOnInteraction: false
      },
      speed: 500,
      effect: "slide"
    }
  }

  changeAdvance(){
    if( !this.advanceSearch ){
      this.advanceSearch = true
    } else {
      this.advanceSearch = false
    }
    
  }

}

