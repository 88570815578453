<div class="dispatchs"  fxLayout="column" fxLayoutAlign="center center" >
    <p class="title-dispatchs">Encuentra el gurú en el portal con mayor audiencia de latinoamérica.</p>

    <div fxLayout="row wrap" fxLayoutAlign="center center" class="info-bar">
        <div *ngFor="let info of dispatchs" fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-xs="25">
            <div class="cards" fxLayout="row"  fxLayoutAlign="center center">
                <mat-icon class="mat-icon-xlg text-muted-icon m-0">{{info.icon}}</mat-icon>
                <div class="content">
                    <h3 fxShow="false" fxShow.gt-sm><b>{{info.title_1}}</b><br> <h3 class="description">{{info.title_2}} </h3></h3> 
                    <h4 fxHide="false" fxHide.gt-sm><b>{{info.title_1}}</b><br> <h4 class="description">{{info.title_2}} </h4></h4> 
                    <span class="text-muted m-0"></span>
                </div>
            </div> 
        </div>
    </div>

    <button class="custom-btn btn-3" (click)="openPage()"><span>Buscar gratis</span></button>
</div>

<hr>
