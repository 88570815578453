<div class="container">
    <h3 mat-dialog-title>Compras Pendientes</h3>
    <div mat-dialog-content>
        <span>Tienes compras pendientes de confirmar con tu banco. Esto podría denegar tus compras en proceso de revisión. ¿Deseas Continuar?</span>
        <div class="table-responsive mt-3">
            <table class="table">
                <thead>
                    <tr>
                        <th>Referencia</th>
                        <th>Monto</th>
                    </tr>
                </thead>
                <tbody *ngFor="let item of data">
                    <tr>
                        <td>{{item.referencia}}</td>
                        <td>{{item.monto}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-between mt-1">
        <button type="button" mat-button (click)="onClose()">Cancelar</button>
        <div class="button-container">
            <div *ngIf="loadSave" class="spinner-container">
            <mat-spinner diameter="24"></mat-spinner>
            </div>
            <button class="msr-3" mat-raised-button color="primary" (click)="onYes()" [disabled]="loadSave">Continuar</button>
        </div>
    </div>
</div>