import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dispatchs',
  templateUrl: './dispatchs.component.html',
  styleUrls: ['./dispatchs.component.scss']
})
export class DispatchsComponent implements OnInit {

  @Input('dispatchs') dispatchs: Array<any> = [];
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {

  }

  openPage(){
    this.router.navigate(['/products']);
  }

}
