import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { ProductDialogComponent } from './product-dialog/product-dialog.component';
import { PlazzaService } from '../../services/plazza.service';
import { Product } from "../../app.models";
import { Settings, AppSettings } from 'src/app/app.settings';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-products-carousel',
  templateUrl: './products-carousel.component.html',
  styleUrls: ['./products-carousel.component.scss']
})
export class ProductsCarouselComponent implements OnInit {

  @Input('products') products: Array<any> = [];
  @Output() returnService = new EventEmitter<DataCustom>();
  //@Output() returnIdProduct = new EventEmitter<any>();

  public config: SwiperConfigInterface = {};
  public settings: Settings;

  public height: number = 25;

  showPrice = false;

  domain = environment.domain

  constructor(public appSettings:AppSettings, 
    public plazzaService:PlazzaService, 
    public dialog: MatDialog, 
    private router: Router,
    private decimalPipe: DecimalPipe) { 
      this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    if (window.innerWidth < 1280) {
      this.height = 33.3;
    }; 
   }
  
  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 16,       
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,  
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2
        },
        960: {
          slidesPerView: 3
        },
        1280: {
          slidesPerView: 4
        },
        1500: {
          slidesPerView: 5
        }
      }
    }
  }

  convertNumber(text){
    if(typeof(text) == 'string'){
      return text.includes('-') ? text : this.transformDecimal(Number(text))
    }else{
      return this.transformDecimal(Number(text))
    }
  }

  transformDecimal(num) {
    return this.decimalPipe.transform(num, '1.2-2');
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    (window.innerWidth < 1280) ? this.height = 33.3 : this.height = 25;
  }

  public openProductDialog(product){   
    let dialogRef = this.dialog.open(ProductDialogComponent, {
        data: product,
        panelClass: 'product-dialog',
        direction: (this.settings.rtl) ? 'rtl' : 'ltr'
    });
    dialogRef.afterClosed().subscribe(product => {
      if(product){
        this.router.navigate(['/products', product.id, product.nombre]); 
      }
    });
  }

  onPayment(data){
    //this.returnIdProduct.emit(data.id)
    const obj: DataCustom = {
      service_id: data.id,
      type: 'order',
      service: data
    }
    this.returnService.emit(obj)
    //this.returnTypeProduct.emit('order')
  }

  openQuoteRequest(data){
    const obj: DataCustom = {
      service_id: data.id,
      type: 'quote',
      service: data
    }
    this.returnService.emit(obj)
    //this.returnIdProduct.emit(data.id)
    //this.returnTypeProduct.emit('quote')
  }

  

}

interface DataCustom {
  service_id : number;
  type: string;
  service: any;
}