import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-supercategory-list',
  templateUrl: './supercategory-list.component.html',
  styleUrls: ['./supercategory-list.component.scss']
})
export class SupercategoryListComponent {

  @Input() superCategories;
  @Output() change: EventEmitter<any> = new EventEmitter();
  mainSuperCategories;

  constructor() { }

  public ngDoCheck() {
    if(this.superCategories && !this.mainSuperCategories) {
      this.mainSuperCategories = this.superCategories; 
    }
  }

  public stopClickPropagate(event: any){
    if(window.innerWidth < 960){
      event.stopPropagation();
      event.preventDefault();
    }    
  }

  public changeSuperCategory(event){
    this.change.emit(event);
  }
}
