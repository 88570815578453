import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlazzaService } from '../../../services/plazza.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit{

  storeList = [];
  categoryList = [];
  productList = [];

  nameS
  //dataSearch = new FormData() 
  //@Input('name') name = ''
  @Output() updateBusqueda: EventEmitter<any> = new EventEmitter();

  @Input() 
    public set name(name: string) {
      /*if(name.length > 2){
        this.nameS = name;
        this.callAllSearch();
      }*/

      this.storeList = [];
      this.categoryList = [];
      this.productList = [];

      if(name != null && name != '') {
        this.nameS = name;
        this.callAllSearch();
      }
    }

  constructor(
    public plazzaService:PlazzaService,
    public snackBar: MatSnackBar
  ) { 

  }

  ngOnInit(): void {
  }

  /*ngAfterViewInit(): void {
    this.searchStore()
    this.searchCategory()
    this.searchProduct()
  }*/

  callAllSearch(){
    this.searchStore()
    this.searchCategory()
    this.searchProduct()
  }

  searchStore(){ 
    let dataSearch = new FormData() 
    dataSearch.append('nombre', this.nameS)
    dataSearch.append('cantidad_x_pagina','10')
    dataSearch.append('numero_pagina','1')

    this.plazzaService.searchStore(dataSearch).subscribe((res:any) =>{
      this.storeList = res.length == 0  ? [] : res.tiendas
    },error => {
      this.snackBar.open(error.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    })
  }

  searchCategory(){
    let dataSearch = new FormData() 
    dataSearch.append('nombre', this.nameS)
    this.plazzaService.searchCategory(dataSearch).subscribe((res:any) =>{
      this.categoryList = res.length == 0 ? [] : res
    },error => {
      this.snackBar.open(error.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    })
  }

  searchProduct(){
    let dataSearch = new FormData() 
    dataSearch.append('nombre', this.nameS)
    dataSearch.append('cantidad_x_pagina','10')
    dataSearch.append('numero_pagina','1')
    this.plazzaService.searchProduct(dataSearch).subscribe((res:any) =>{
      this.productList = res.length == 0 ? [] : res.data
    },error => {
      this.snackBar.open(error.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    })
  }

  selectSearch(event){
    this.updateBusqueda.emit(event);
  }
}
