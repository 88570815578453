import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data, PlazzaService } from '../../services/plazza.service';
import { Product, ProductPlazza } from '../../app.models';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  @Input() product: ProductPlazza;
  @Input() type: string;
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
  public count:number = 1;
  public align = 'center center';
  constructor(public plazzaService:PlazzaService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    if(this.product){
      /*if(this.product.cartCount > 0){ //creo que aqui se usa de cuantos ya existe agregado
        this.count = this.product.cartCount;
      }*/
    }  
    this.layoutAlign(); 
  }

  public layoutAlign(){
    if(this.type == 'all'){
      this.align = 'space-between center';
    }
    else if(this.type == 'wish'){
      this.align = 'start center';
    }
    else{
      this.align = 'center center';
    }
  }



  public increment(count){
    if(this.count < this.product.inventario){
      this.count++;
      let obj = {
        productId: this.product.id,
        soldQuantity: this.count,
        total: this.count * this.product.precio
      }
      this.changeQuantity(obj);
    }
    else{
      this.snackBar.open('No puedes agregar más items. En stock ' + this.count + ' items.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }    
  }

  public decrement(count){
    if(this.count > 1){
      this.count--;
      let obj = {
        productId: this.product.id,
        soldQuantity: this.count,
        total: this.count * this.product.precio
      }
      this.changeQuantity(obj);
    }
  }

  public addToCompare(product:Product){
    this.plazzaService.addToCompare(product);
  }

  public addToWishList(product:Product){
    this.plazzaService.addToWishList(product);
  }

  public addToCart(product:Product){
    // console.log(product)
    let currentProduct = this.plazzaService.Data.cartList.filter(item=>item.id == product.id)[0];
    if(currentProduct){
      if((currentProduct.cartCount + this.count) <= this.product.inventario){
        product.cartCount = currentProduct.cartCount + this.count;
      }
      else{
        this.snackBar.open('No puedes agregar más items. En stock ' + this.product.inventario + ' items y puedes agregar ' + currentProduct.cartCount + ' item a tu carrito', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        return false;
      }
    }
    else{
      product.cartCount = this.count;
    }
    this.plazzaService.addToCart(product);
  }

  public openProductDialog(event){
    this.onOpenProductDialog.emit(event);
  }

  public changeQuantity(value){
      this.onQuantityChange.emit(value);
  }

}