import { Component, ViewEncapsulation, OnInit, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { PlazzaService } from '../../../services/plazza.service';
import { Product } from '../../../app.models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfileService } from 'src/app/services/cliente/profile.service';
import { SnackBarService } from 'src/app/admin/services/system/snackbar.service';
import { Router } from '@angular/router';
import { ErrorManagementService } from 'src/app/admin/services/system/error-management.service';
import { DialogCustomComponent } from 'src/app/dialog-custom/dialog-custom.component';
import { ProductRetentionReceiptComponent } from 'src/app/pages/products/product-retention-receipt/product-retention-receipt.component';
import { ProductPaymentComponent } from 'src/app/pages/products/product-payment/product-payment.component';
import { QuoteRequestComponent } from 'src/app/pages/products/quote-request/quote-request.component';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductDialogComponent implements OnInit {
  public config: SwiperConfigInterface = {};

  /*services = [
    {
      id: 1,
      nombre: "DISEÑO DE PRESENTACIONES",
      descripcion: "DISEÑO DE PRESENTACIONES",
      precio: 350.00,
      description: "Realizamos, talleres, presentaciones audivisuales, elaboracion de carpetas fisicas o digitales. diseño de logos, publicidad en redes sociales.",
    },
    {
      id: 2,
      nombre: "DISEÑO DE PRESENTACIONES",
      descripcion: "DISEÑO DE PRESENTACIONES, CATALOGOS Y ANUNCIOS PUBLICITARIOS DIGITALES",
      precio: 100.00,
      description: "Realizamos, talleres, publicidad en redes sociales.",
    },
    {
      id: 3,
      nombre: "DISEÑO DE PRESENTACIONES",
      descripcion: "DISEÑO DE PRESENTACIONES, CATALOGOS Y ANUNCIOS PUBLICITARIOS DIGITALES",
      precio: 50.00,
      description: "Realizamos, talleres, presentaciones audivisuales, elaboracion de carpetas fisicas o digitales. diseño de logos, publicidad en redes sociales.",
    },
    {
      id: 4,
      nombre: "DISEÑO DE PRESENTACIONES",
      descripcion: "DISEÑO DE PRESENTACIONES, CATALOGOS Y ANUNCIOS PUBLICITARIOS DIGITALES",
      precio: 200.00,
      description: "Realizamos, talleres, presentaciones audivisuales, elaboracion de carpetas fisicas o digitales. diseño de logos, publicidad en redes sociales.",
    }
  ]*/

  services = [
    {
      precio_descuento: "315.00",
      puntos_acumular: "0.00",
      necesita_bin: 0,
      id_promo: 32,
      descuento: "10",
      tipo_promocion: 1,
      necesita_cupon: 0,
      id: 2052,
      nombre: "Reparación de celular",
      descripcion: "Reparación de celulares, limpieza, cambio de vidrio, cambio de vidrio templado, revisión.",
      imagen: 2052,
      precio: "350",
      precio_fijo: 1,
      subcategoria: "Revision y reparacion de su celular",
      categoria: 52,
      guru: 624
    },

    {
      precio_descuento: "0",
      puntos_acumular: "0.00",
      necesita_bin: 0,
      id_promo: 32,
      descuento: "10",
      tipo_promocion: 1,
      necesita_cupon: 0,
      id: 2052,
      nombre: "Reparación de celular",
      descripcion: "Reparación de celulares, limpieza, cambio de vidrio, cambio de vidrio templado, revisión.",
      imagen: 2052,
      precio: 0,
      precio_fijo: 0,
      subcategoria: "Revision y reparacion de su celular",
      categoria: 52,
      guru: 624
    },    
    {
      precio_descuento: "315.00",
      puntos_acumular: "0.00",
      necesita_bin: 0,
      id_promo: 32,
      descuento: "10",
      tipo_promocion: 1,
      necesita_cupon: 0,
      id: 2052,
      nombre: "Reparación de celular",
      descripcion: "Reparación de celulares, limpieza, cambio de vidrio, cambio de vidrio templado, revisión.",
      imagen: 2052,
      precio: "350",
      precio_fijo: 1,
      subcategoria: "Revision y reparacion de su celular",
      categoria: 52,
      guru: 624
    },

    {
      precio_descuento: "0",
      puntos_acumular: "0.00",
      necesita_bin: 0,
      id_promo: 32,
      descuento: "10",
      tipo_promocion: 1,
      necesita_cupon: 0,
      id: 2052,
      nombre: "Reparación de celular",
      descripcion: "Reparación de celulares, limpieza, cambio de vidrio, cambio de vidrio templado, revisión.",
      imagen: 2052,
      precio: 0,
      precio_fijo: 0,
      subcategoria: "Revision y reparacion de su celular",
      categoria: 52,
      guru: 624
    },    
  ]

  id_guru:any;
  guru:any;
  list_categories:any;
  list_services:any;

  retention: any;
  
  service_id: any;
  service_data: any;

  obs: Subscription[] = []
  user = null

  constructor(public plazzaService:PlazzaService, 
    public dialogRef: MatDialogRef<ProductDialogComponent>,
    private snackBar: MatSnackBar,
    private profileService: ProfileService,
    private snackBarService: SnackBarService,
    private router: Router,
    public dialog: MatDialog,
    private errorService: ErrorManagementService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: Product) { 
      this.obs.push(
        this.authService.currentUser.subscribe(res => {
          this.user = res == null ? null : res
        })
      )
  }

  ngOnInit() {
    this.id_guru = this.data
    this.getServiciosXCategoriaXGuru()
    this.getRetention()
  }

  ngAfterViewInit(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true, 
      effect: "fade",
      fadeEffect: {
        crossFade: true
      }
    }
  }

  getServiciosXCategoriaXGuru(){
    let send = {
      id_guru: this.id_guru,
      //id_categoria: category_id
    }

    let dataP = new FormData()
    
    for(let key in send){  
      dataP.append(key,send[key])
    }

    this.plazzaService.getServicioXCategoriaXGuru(dataP).subscribe((data:any)=>{
      if(data != null){
        this.list_services = data; 
        //console.log('list category',this.list_services)
      } 
      if(data == -1) {
        this.list_services = []
      }
      
    }, error =>{
      this.snackBar.open(error.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  indexService(element){
    console.log('indexService', element)

    if(element.type == 'order'){

      this.onPayment()
      this.service_id = element.service_id
      this.service_data = element.service

    } else {
      if(element.type == 'quote'){
        this.openQuoteRequest(element.service_id)
      }
    }
  }

  onPayment(){

    if(this.user == null){
      this.router.navigate(['/ingresar']);
      return;
    }

    let send = {
      title: "Enviar",
      body: "¿Esta seguro de realizar esta compra?",
      no: "Cancelar",
      yes: "Confirmar"
    }
    const dialogRef = this.dialog.open(DialogCustomComponent, {
      width: "300px",
      data: send,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined || result == "Cancel") {
        console.log('close')
      } else {
        if (result == 'yes') {
          /*this.itemService.deleteItem(element.code).subscribe(re => {
            console.log(re)
            this.toastr.success("Se eliminó correctamente")
            this.onFilter()
          }, error => {
            this.errorService.getErrorMessage(error)
          })*/
          this.openRetentionReceipt()
        } 
      }
    });
  }
  

  getRetention(){
    //Falta traer id de guru en cotizacion
    //let id_guru = 624
    
    let send = {
      id_guru: this.id_guru,
    }

    let dataP = new FormData()
    
    for(let key in send){  
      dataP.append(key,send[key])
    }

    this.profileService.getRetencion(dataP).subscribe((data:any)=>{
      if(data != null && data != -1){
        this.retention= data; 
        //console.log('retencion',this.retention)
      } else {
        this.retention = []
      }      
    }, error =>{
      this.snackBar.open(error.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    });
  }


  openRetentionReceipt(){
    const dialogRef = this.dialog.open(ProductRetentionReceiptComponent, {
      width: "650px",
      data: { data: this.retention },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res === undefined || res.result == "Close") {
        console.log('Close')
      } else {
        if (res.result == 'Success') {
          console.log('Success RetentionReceipt')
          //console.log(res)
          /*
            id:1->12.5%
            id:2->25%
            id:3->1%
            id:4->ISV
          */
          
          //result.obj.files.file.base64 ? result.obj.files.file.base64.split("base64,")[1] : null
          if(res.obj.retentions == null){
            this.openProductPayment(res.obj, null)
          }else {
            this.getRetentionByProduct(res.obj)
          }

          

        }
      }
    });
  }

  getRetentionByProduct(element){

    console.log('retenciones', element)

    let send = {
      id_producto: this.service_id
    }

    /*
      id:1->12.5%
      id:2->25%
      id:3->1%
      id:4->ISV
    */
    element.retentions.forEach(e => {
      switch(e.retention_id) { 
        case 1: { 
          let auxRetention125 = { archivo_12: 1 }
          send = {...send, ...auxRetention125 }
          break; 
        } 
        case 2: { 
          let auxRetention25 = { archivo_25: 1 }
          send = {...send, ...auxRetention25 }
          break; 
        }
        case 3: { 
          let auxRetention1 = { archivo_1: 1 }
          send = {...send, ...auxRetention1 }
          break; 
        } 
        case 4: { 
          let auxRetentionISV = { archivo_isv: 1 }
          send = {...send, ...auxRetentionISV }
          break; 
        } 
        default: { 
          //statements; 
          break; 
        } 
      }
    });

    console.log('datos para obtener retencion x producto', send)

    let dataP = new FormData()
    
    for(let key in send){  
      dataP.append(key,send[key])
    }
    this.profileService.getRetencionXProduct(dataP).subscribe((res:any)=>{
      if(res != null && res != []){
        let service_data_retention = res
        this.openProductPayment(element, service_data_retention)
      }
    }, error =>{
      this.snackBar.open(error.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    });
  }

  openProductPayment(element, data_retention){
    const dialogRef = this.dialog.open(ProductPaymentComponent, {
      width: "80%",
      data: { id_servicio: this.service_id, data: this.service_data, retention: element, retention_data: data_retention},
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined || result == "Close") {

      } else {
        if (result == 'success') {
          this.dialogRef.close();
        }
      }
    });
  }

  

  solicitarCotizacion(id, data){
    let send = {
      id_servicio: id
    }

    if(data.comment != null && data.comment != ''){
      //console.log(this.form.value.comment)
      let comentario = { comentario: data.comment }
      send = {...send, ...comentario }
    }
    
    console.log('data files', typeof data.files)
    if(data.files.file != null){
      let file = { 
        nombre_adjunto: data.files.file.fileName,
        adjunto: data.files.file.base64.split("base64,")[1],
      }
      send = {...send, ...file }
    }

    console.log('datos de cotizacion', send)

    let dataP = new FormData()
    
    for(let key in send){  
      dataP.append(key,send[key])
    }

    this.profileService.solicitarCotizacion(dataP).subscribe((data:any)=>{
      if(data != null){
        this.snackBarService.success({ message: data.razon})
        this.router.navigate(['/cuenta/quotes']);
        this.dialogRef.close();
      }
      
    }, error =>{
      this.errorService.getErrorMessage(error)
    });
    
  }

  openQuoteRequest(element){

    if(this.user == null){
      this.router.navigate(['/ingresar']);
      return;
    }

    console.log('abrir')

    const dialogRef = this.dialog.open(QuoteRequestComponent, {
      width: "650px",
      data: { service: element },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res.result === undefined || res.result == "Close") {

      } else {
        if (res.result == 'success') {
          console.log(res)
          console.log(element)
          //console.log('result', res.obj)
          this.solicitarCotizacion(element, res.obj)
        }
      }
    });
  }
}