<!--<div class="row">
  <div class="col carousel">-->
<div fxLayout="row" fxLayoutAlign="center start" style="margin: auto 0px !important;">
  <div class="main-slider banner-left">
    <div class="swiper-container h-100" [swiper]="config">
      <div class="swiper-wrapper h-100">      
        <div *ngFor="let slide of slides" class="swiper-slide">
          <div [attr.data-background] ="slide.image" class="slide-item swiper-lazy">
              <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
              <div class="mask"></div>  
              <!--<div fxLayout="column" fxLayoutAlign="center center" class="content">
                <h1>{{slide.title}}</h1>
                <h3>{{slide.subtitle}}</h3>
                <button mat-raised-button color="primary">Shop now</button>
              </div> -->
          </div>
        </div>
      </div>
      <div class="swiper-pagination white"></div>
      <button mat-mini-fab color="primary" class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
      <button mat-mini-fab color="primary" class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="center stretch" class="container-5 banner-right">
    <div fxLayout="column" fxLayoutAlign="center center" class="btn btn-five" (click)="openPage('1')">
      <span><mat-icon class="mat-icon-xlg text-muted-icon m-0" style="color: rgb(255, 255, 255);">group</mat-icon></span>
      <span style="color: rgb(255, 255, 255);">Alianzas Estratégicas</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" class="btn btn-five" (click)="openPage('2')">
      <span><mat-icon class="mat-icon-xlg text-muted-icon m-0" style="color: rgb(255, 255, 255);">help</mat-icon></span>
      <span style="color: rgb(255, 255, 255);">Preguntas Frecuentes</span>
    </div>
    <!--<div fxLayout="column" fxLayoutAlign="center center" class="btn btn-five"(click)="openPage('3')">
      <span><mat-icon class="mat-icon-xlg text-muted-icon m-0" style="color: rgb(255, 255, 255);">videocam</mat-icon></span>
      <span style="color: rgb(255, 255, 255);">Sala de Prensa</span>
    </div>-->
  </div>
  <!--<div class="col">
    <div class="row">
      <div id="categ" class="col cat-car">
        <div class="container-5">
          <div class="btn btn-five">
            <span><mat-icon class="mat-icon-xlg text-muted-icon m-0" style="color: rgb(255, 255, 255);">home</mat-icon></span><br>
            <span style="color: rgb(255, 255, 255);">Gurús para Hogar</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div id="categ" class="col cat-car">
        <div class="container-5">
          <div class="btn btn-five">
            <span><mat-icon class="mat-icon-xlg text-muted-icon m-0" style="color: rgb(255, 255, 255);">work</mat-icon></span><br>
            <span style="color: rgb(255, 255, 255);">Alianzas Estratégicas</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div id="categ" class="col cat-car">
        <div class="container-5">
          <div class="btn btn-five">
            <span><mat-icon class="mat-icon-xlg text-muted-icon m-0" style="color: rgb(255, 255, 255);">laptop</mat-icon></span><br>
            <span style="color: rgb(255, 255, 255);">Preguntas Frecuentes</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div id="categ" class="col cat-car">
        <div class="container-5">
          <div class="btn btn-five">
            <span><mat-icon class="mat-icon-xlg text-muted-icon m-0" style="color: rgb(255, 255, 255);">videocam</mat-icon></span><br>
            <span style="color: rgb(255, 255, 255);">Sala de Prensa</span>
          </div>
        </div>
      </div>
    </div>
  </div>
-->
</div>
