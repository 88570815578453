import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MethodPayService } from '../../services/both/methodPay.service';
import { AuthService } from '../../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dilo-load-pay',
  templateUrl: './dilo-load-pay.component.html',
  styleUrls: ['./dilo-load-pay.component.scss']
})
export class DiloLoadPayComponent implements OnInit {

  previewData: any
  load: boolean = false

  state = 'LOAD' // 'ERROR' 'SUCCESS'
  interval;
  diloId;
  
  messageError = ''
  reference = ''
  dateMessage = ''

  max = 900000
  init = 0

  user = null
  session = null
  
  constructor(
    private methodPayService: MethodPayService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<DiloLoadPayComponent>, 
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.diloId = this.data.dilo.IdPeticion
    if(this.data.type == 'User'){
      this.user = this.authService.currentUserValue
    }else{
      this.user = this.authService.currentAdminValue
    }
    this.session = this.user ? this.user.id_sesion : null
  }

  ngOnInit() {
    this.openViewPay()
    this.interval = setInterval(() => {
      this.onGetLogOrder()
    }, 10000);
  }

  openViewPay(){
    try {
      let popupWin = window.open(this.data.dilo.url, '_blank');
      //popupWin.document.write();
      popupWin.document.close();

    } catch (error) {

    }
  }

  onGetLogOrder() {
    let data = new FormData()
    data.append('id_peticion', this.diloId)

    this.methodPayService.validateDilo(this.session,data).subscribe((res: any) => {

      /*
      000: Aceptado
      008: En espera
      013: Cancelada
      */

      if(res.StatusCode == '000'){
        this.state = 'SUCCESS'
        this.reference = res.RequestInfo ? res.RequestInfo.referencia : ''
        this.dateMessage = res.RequestInfo ? res.RequestInfo.Fecha : ''
        clearInterval(this.interval)
        return
      }
      if(res.StatusCode == '013'){
        this.state = 'ERROR'
        this.messageError = res.Descripcion
        this.reference = res.RequestInfo ? res.RequestInfo.referencia : ''
        this.dateMessage = res.RequestInfo ? res.RequestInfo.Fecha : ''
        clearInterval(this.interval)
        return
      }
      if(res.StatusCode == '008'){
        this.init = this.init + 10000
        if (this.init >= this.max) {
          this.state = 'EXPIRED'
          clearInterval(this.interval)
        }else{
          this.state = 'LOAD'
        }
        return
      }
    }, error => {
      this.snackBar.open(error.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    })
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  closeCancelar(){
    let data = new FormData()
    data.append('id_peticion', this.diloId)

    this.methodPayService.cancelDilo(this.session,data).subscribe((res: any) => {
      if(res.respuesta == 0){
        this.state = 'ERROR'
        this.closeSuccess()
      }else{
        this.snackBar.open(res.razon, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }
    }, error => {
      this.snackBar.open(error.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    })
  }

  closeSuccess() {
    this.dialogRef.close({state: this.state, referencia: this.reference})
  }
}