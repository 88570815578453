<div class="brands-carousel"> 
    <div class="theme-container text-center title-main">Categorías</div>
    <div *ngIf="brands.length == 0" class="w-100 py-4 text-center">
        <span>Sin registros</span>
    </div>
    <ng-container *ngIf="brands.length > 0">
        <div class="swiper-container h-100" [swiper]="config">
            <div class="swiper-wrapper h-100">      
                <div *ngFor="let brand of brands" class="swiper-slide">
                    <div class="p-0">
                        <a [routerLink]="['/products', brand.nombre, 'category', brand.id]" class="brand-item">
                            <img [attr.data-src]="plazzaService.getPathImageCategory(brand.id)" class="swiper-lazy"
                            onerror="this.src='assets/images/others/not-found.png';">
                        </a>
                        <div class="text-center">{{brand.nombre}}</div>
                        <div class="swiper-lazy-preloader"></div>
                    </div>
                </div>
            </div> 
            <div class="text-right"><a mat-button routerLink="/categorías" class="link"><i>Ver todos</i></a></div>
            <button mat-icon-button class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
            <button mat-icon-button class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button> 
        </div>
    </ng-container>
    <hr>
</div>