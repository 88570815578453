import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import {trigger, state, style, animate, transition} from '@angular/animations';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { PlazzaService } from 'src/app/services/plazza.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('200ms ease-out')),
      transition('default => rotated', animate('200ms ease-in'))
  ])
]
})
export class SearchBoxComponent implements OnInit {
  public form: FormGroup

  @Input('slides') slides: Array<any> = [];
 
  public dispatchs = {
    hogar: true,
    empresa: false,
    digital: false
  };

  public config: SwiperConfigInterface = {};

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };

  advanceSearch = false
  
  state: string = 'default';

  dropdownList = [];
  dropdownSettings:IDropdownSettings={};

  // Departamentos
  loadSuperGuruss = false
  listSuperGuruss: any[] = []
  listFilterSuperGurus: any[] = []
  searchSuperGurus = ''
  nothingDepart = false

  title = "CodeSandbox";
  singleSelect: any = [];
  
  location = new FormGroup({
    superCategory: new FormControl(),
    country: new FormControl(),
    department: new FormControl(),
    municipality: new FormControl(),
  });
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  countries: string[] = ['Honduras', 'Perú']
  deparments: string[] = ['Francisco Morazán','Atlántida','Choluteca','Colón','Comayagua','Copán','Cortés','El Paraíso','Gracias a Dios','Intibucá','Islas de la Bahía']
  municipalities: string[] = ['San Pedro Sula','Choloma','La Lima','Omoa','Pimienta','Potrerillos','Puerto Cortés','San Antonio de Cortés','San Francisco de Yojoa','San Manuel','Santa Cruz de Yojoa','Villanueva']

  search = ''

  viewHeader = true
  message = null

  constructor(
    public plazzaService:PlazzaService,
    private router: Router,
    private fb: FormBuilder, 
    public snackBar: MatSnackBar
    ) {
    this.form = this.fb.group({
      country: [null,  Validators.compose([
        Validators.required,
      ])],
      department: [null,  Validators.compose([
        Validators.required,
      ])],
      municipality: [null,  Validators.compose([
        Validators.required,
      ])],
    })
  }

  ngOnInit() { 
    this.dropdownList = [
      { item_id: 1, item_text: 'Item1' },
      { item_id: 2, item_text: 'Item2' },
      { item_id: 3, item_text: 'Item3' },
      { item_id: 4, item_text: 'Item4' },
      { item_id: 5, item_text: 'Item5' }
    ];
    this.dropdownSettings = {
      idField: 'item_id',
      textField: 'item_text',
    };

    //this.getMessageHeader()
    this.getSuperCategories()
  }

  ngAfterViewInit(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,     
      autoplay: {
        delay: 6000,
        disableOnInteraction: false
      },
      speed: 500,
      effect: "slide"
    }
  }

  changeAdvance(){
    if( !this.advanceSearch ){
      this.advanceSearch = true
    } else {
      this.advanceSearch = false
    }
    
    this.state = (this.state === 'default' ? 'rotated' : 'default');
  }

  onSearch(){
    this.router.navigate(['/products'], { queryParams: { search: this.search, superCategory: this.location.value.superCategory} });
  }

  getSuperCategories(){
    this.plazzaService.getSupercategories().subscribe((data: any) => {
      this.listSuperGuruss = data.filter(ele => ele.tipo == 1)
      this.listFilterSuperGurus = this.listSuperGuruss.slice()
    },error => {
      this.snackBar.open(error.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    })
  }

  changeSelectSuperCategory(selectedValues: any) {
    //console.log(this.dispatchs, selectedValues.value)
    //if(selectedValues.value.length != 0){}
    switch(selectedValues.value){
      case 1:{
        this.dispatchs.hogar = true;
        this.dispatchs.empresa = false;
        this.dispatchs.digital = false;
        break;
      }
      case 2:{
        this.dispatchs.hogar = false;
        this.dispatchs.empresa = true;
        this.dispatchs.digital = false;
        break;
      }
      case 3:{
        this.dispatchs.hogar = false;
        this.dispatchs.empresa = false;
        this.dispatchs.digital = true;     
        break;
      }
      default: {
        break;
      }
    }
  }

  indexSuper(element){
    this.location.get('superCategory').setValue(element)
  }

  getMessageHeader(){
    this.plazzaService.getConfiguration().subscribe((data:any)=>{
      this.message = data.mensaje ? data.mensaje : null;
    })
  }

  closeHead(){
    this.viewHeader = false
  }
}

